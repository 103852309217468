import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { Map } from "../mapa/Mapa";

const Mapm = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [file, setFile] = useState();
  const { user, isAuthenticated } = useAuth(); // Adiciona isAuthenticated para verificar login

  const saveFile = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("kml", file);

    try {
      const res = await axios.put(`${url}/kml`, formData, {
        headers: { authorization: `Bearer ${user.access_token}` },
      });
      console.log(res.data);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div
      className="container-sm pt-4"
      style={{
        backgroundColor: "#ECF2F8",
        boxSizing: "border-box",
      }}
    >
      <div className="clearfix mb-1">
        {/* Renderiza a parte de upload apenas se o usuário estiver autenticado */}
        {isAuthenticated && (
          <>
            <input
              className="form-control form-control-sm w-25 float-start"
              id="formFileSm"
              type="file"
              onChange={saveFile}
            />
            <button
              className="btn btn-sm text-light ms-1 sidItemL2"
              onClick={uploadFile}
            >
              Actualizar
            </button>
          </>
        )}
      </div>

      <Map style={{ width: "100%", height: "600px" }} />
    </div>
  );
};

export default Mapm;
