import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {toast } from 'react-toastify';
import { solveMessage } from "../utils";

const url = process.env.REACT_APP_BACKEND_BASE_URL;

const classificationOptions = [
  { key: "1ª", value: "1ª" },
  { key: "2ª", value: "2ª" },
  { key: "3ª", value: "3ª" },
  { key: "4ª", value: "4ª" },
  { key: "5ª", value: "5ª" },
];

export const useRoadForm = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [loteOptions, setLoteOptions] = useState([]);
  const [formData, setFormData] = useState({
    code: "",
    name: "",
    classification: "",
    extremes: "",
    length: 0,
    lote: "",
    zone: "",
    price: 0,
    connected: false,
    connectedCode: "",
  });

  const getZones = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${url}/zone`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setZoneOptions(response.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }, [user.access_token, setZoneOptions]);

  const getLotes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${url}/lote`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setLoteOptions(response.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  }, [user.access_token, setLoteOptions]);

  useEffect(() => {
    getZones();
    getLotes();
  }, [getZones, getLotes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${url}/road`, formData, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      toast.success('Adicionado com Sucesso', {style: {fontSize: '13.3px'}});
      navigate("/listRoad");
    } catch (error) {
      toast.error(solveMessage(error), {style: {fontSize: '13.3px'}});
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "connected") {
      if (e.target.checked) {
        const road = JSON.parse(e.target.dataset.road);
        setFormData({
          ...formData,
          ...road,
          connected: true,
          connectedCode: road.code,
        });
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]:
          e.target.type === "number" ? Number(e.target.value) : e.target.value,
      });
    }
  };

  return {
    handleSubmit,
    handleChange,
    formData,
    classificationOptions,
    loteOptions,
    zoneOptions,
    loading,
  };
};
