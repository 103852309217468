import axios from "axios";
import { useState } from "react";
import { solveMessage } from "../utils";

const AddImage = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [files, setFiles] = useState();

  const saveFile = (e) => {
    setFiles(e.target.files);
  };

  const uploadFile = async () => {
    const evaluationId = 1;
    const formData = new FormData();

    Object.values(files).forEach((file) => {
      formData.append("photos[]", file);
    });

    try {
      const res = await axios.post(
        `${url}/image/uploads/${evaluationId}`,
        formData
      );
      console.log(res.data.message);
    } catch (error) {
      console.log(solveMessage(error));
    }
  };

  return (
    <div className="container-sm p-5 my-5 text-black ">
      <h3 mx-auto p-2>
        Adicionar Imagem
      </h3>
      <form className="border p-3">
        <input type="file" name="photo" multiple onChange={saveFile} />
        <button
          type="button"
          className="btn btn-success btn-block"
          onClick={uploadFile}
        >
          Upload
        </button>
      </form>
    </div>
  );
};

export default AddImage;
