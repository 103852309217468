import { MapContainer, TileLayer } from "react-leaflet";
import { KmlLayer } from "./KmlLayer";
import { EvaluationLayer } from "./EvaluationLayer";

export const Map = (props) => {
  const position = [15.855352, -23.96268];
  const zoomLevel = 8.4;
  const { setMapRoad, ...rest } = props;

  return (
    <div>
      <MapContainer
        center={position}
        zoom={zoomLevel}
        scrollWheelZoom={true}
        style={{ width: "100%", height: "600px" }}
        {...rest}
      >
         <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        /> 
        <KmlLayer setMapRoad={setMapRoad} />
        <EvaluationLayer
          evaluationPoints={props.evaluationPoints}
          setEvaluationPoints={props.setEvaluationPoints}
          editable={props.editable}
        /> 
      </MapContainer>
    </div>
  );
};
