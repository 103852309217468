import { useState } from "react";
import { Link } from "react-router-dom";

export const Dropdown = ({ title, icon, items }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="list-group-item list-group-item-action pb-3 d-flex justify-content-between sidItemL text-white text-hover"
        onClick={() => setOpen(!open)}
      >
        <span>
          <i className={icon}></i>
          <span>{title}</span>
        </span>
        {open ? (
          <i className="fas fa-chevron-up align-self-center" />
        ) : (
          <i className="fas fa-chevron-down align-self-center" />
        )}
      </button>
      {open && (
        <ul>
          {items.map((item) => (
            <li key={item.label} className="text-white ms-4">
              <Link
                to={item.to}
                className="text-white fw-light"
                style={{ textDecoration: "none" }}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
