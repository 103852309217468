import { inconformPerc, conformPerc, inconformCount } from "../utils.js";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

const ViewEvaluationCompany = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [contractName, setContractName] = useState("");
  const [roadCodigo, setRoadCodigo] = useState("");
  const [roadExtens, setRoadExtens] = useState("");
  const [evaluation, setEvaluation] = useState([]);
  const [positions, setPositions] = useState([]);
  const [tipServic, setTipServic] = useState("");
  const [categoria, setCategoria] = useState("");
  const [userName, setUserName] = useState("");
  const [section, setSection] = useState([]);
  const { cod } = useParams();
  const tableHeadColor = {color: '#6080A3'}

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${url}/evaluation/evaluat/${cod}`);
        setEvaluation(response.data[0]);
        setSection(response.data[0].section);
        setPositions(response.data[0].positions);
        setTipServic(response.data[0].typeservice.name);
        setCategoria(response.data[0].criterion.name);
        setContractName(response.data[0].section.contract.name);
        setRoadCodigo(response.data[0].section.road.codigo);
        setRoadExtens(response.data[0].section.road.extensao)
        setUserName(response.data[0].section.user.name);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [
    url,
    cod,
    setEvaluation,
    setSection,
    setPositions,
    setTipServic,
    setCategoria,
    setContractName,
    setRoadCodigo,
    setUserName,
  ]);

  const renderImage = async (image) => {
    var img = `<img src='${url}/image/streamFile/${image}' alt='' />`;
    var poupup = window.open();
    poupup.document.write(img);
  };
 
  return (
    <div className="container-sm pt-4 text-black">
      <div className="w-75 mx-auto">
      <h5 className="mb-2" style={{ fontFamily: "Roboto"}}>
       <span className="fw-lighter">Avaliação
          <small className="text-muted ms-2">
            {positions.length > 0 && positions[0].phase}º Mez
          </small>
        </span>
        <span className="fw-lighter ms-2">Contrato
          <small className="text-muted ms-2">
            {contractName}
          </small>
        </span>
        <span className="fw-lighter ms-1">
            Estrada
            <small className="text-muted ms-2">
             {roadCodigo}/{roadExtens}Km
            </small>
        </span>
        <span className="fw-lighter ms-1">
            Troço
            <small className="text-muted ms-2">
             {section.kmInicial} A {section.KmFinal} Km
            </small>
        </span> 
        
      </h5> 

        <table className="table table-bordered shadow-lg bg-white p-3 smollText">
          <thead className="text-center">
            <tr>
              <th style={tableHeadColor}>Código de Estrada</th>
              <th>{roadCodigo}</th>
            </tr>
            <tr style={{ backgroundColor: "#F3F3F3" }}>
              <th style={tableHeadColor}>Serviço</th>
              <th>{tipServic}</th>
            </tr>
            <tr>
              <th style={tableHeadColor}>SubServiço</th>
              <th>{tipServic !== "Pavimento da Via" ? "" : categoria}</th>
            </tr>
            <tr style={{ backgroundColor: "#F3F3F3" }}>
              <th style={tableHeadColor}>Anomalias</th>
              <th>
                {inconformCount(positions, section.evaluationParameter) > 0 ? (
                  <i className="fas fa-times text-danger"></i>
                ) : (
                  <i className="fa fa-check text-success"></i>
                )}
              </th>
            </tr>
            <tr>
              <th style={tableHeadColor}>Km Nao Conforme </th>
              <th>{inconformCount(positions, section.evaluationParameter) + "Km"}</th>
            </tr>
            <tr style={{ backgroundColor: "#F3F3F3" }}>
              <th style={tableHeadColor}>% Nao Conforme </th>
              <th>
                {inconformPerc(
                  inconformCount(positions, section.evaluationParameter),
                  section.KmFinal,
                  section.kmInicial
                ) + " %"}
              </th>
            </tr>
            <tr>
              <th style={tableHeadColor}>% Conforme </th>
              <th>
                {conformPerc(
                  inconformCount(positions, section.evaluationParameter),
                  section.KmFinal,
                  section.kmInicial
                ) + " %"}
              </th>
            </tr>
            <tr style={{ backgroundColor: "#F3F3F3" }}>
              <th style={tableHeadColor}>Coeficiente Ponderação</th>
              <th>
                {inconformCount(positions, section.evaluationParameter) > 0 ? evaluation.ponderacao : 0}
              </th>
            </tr>
            <tr>
              <th style={tableHeadColor}>Data Criação </th>
              <th>
                {!evaluation.created_at
                  ? ""
                  : new Date(evaluation.created_at).getDate() +
                    "/" +
                    (new Date(evaluation.created_at).getMonth() + 1) +
                    "/" +
                    new Date(evaluation.created_at).getFullYear() +
                    " " +
                    new Date(evaluation.created_at).getHours() +
                    ":" +
                    new Date(evaluation.created_at).getMinutes() +
                    ":" +
                    new Date(evaluation.created_at).getSeconds()}
              </th>
            </tr>
          </thead>
        </table>

        {positions.map((position) => (
          <div
            className="card text-center"
            style={{ marginTop: "-10px", borderColor: '#ecf2f8'}}
          >
            <div
              className="card-header text-white"
              style={{ backgroundColor: "#122C48" }}
            >
              {position.isConform === false ? (
                <div>
                  {position.startPosition}   A  {position.endPosition} Km
                  <i className="fas fa-times text-danger ms-2"></i>
                </div>
              ) : (
                <div>
                  {position.startPosition}   A  {position.endPosition} Km
                  <i className="fa fa-check text-success ms-2"></i>
                </div>
              )}
            </div>
            <div className="card-body" style={{marginTop: '-12px'}}>
              <div className="row mx-auto ">
                {position.length === 0
                  ? ""
                  : position.images.map((image) => (
                      image.status === false ? '' :
                      <div className="col-sm-4 p-1 ">
                        <img
                          src={`${url}/image/streamFile/${image.filename}`}
                          style={{ width: "100%", height: "120px" }}
                          onClick={() => renderImage(image.filename)}
                          alt=""
                        />
                      </div>
                    ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewEvaluationCompany;
