import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import himg from "./himg.svg";

const Home = () => {
  
  return (
    <div className="pt-5 container-sm">
      <div className="row">
        <div className="col-sm-5">
           <div className="ps-5">
            <p className="ic" style={{fontFamily: "Roboto"}}>
              <h5>Sejam bem-vindo</h5>
              <h4>À Gestão de Estrada</h4>
             </p>
             <p>
               Lorem ipsum dolor sit amet consectetur. Justo sit egestas sit ornare quis vitae 
               eget enim. Sed lectus tortor nunc aliquet eget ipsum venenatis. Amet varius commodo 
               convallis blandit egestas euismod gravida enim.
               Lorem ipsum dolor sit amet consectetur. Justo sit egestas sit ornare quis vitae 
               eget enim. Sed lectus tortor nunc aliquet eget ipsum venenatis. Amet varius commodo 
               convallis blandit egestas euismod gravida enim.
             </p>
           </div>
           
        </div>
        <div className="col-sm-7">
           <img className="lg" src={himg} style={{ width: "80%", height: "100%" }} alt="" />
        </div>
      </div>
    </div>
  )
};

export default Home;
