import axios from "axios";
import {toast } from 'react-toastify';
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { solveMessage } from "../utils";


const BACKGROUND_STYLE = {
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    backgroundColor: "rgb(0,0,0, 0.7)",
    //sobre põe uma tela sobre outra
    zIndex: "1000",
    overflowY: "auto",
  };
  
  const MODAL_STYLE = {
    position: "fixed",
    top: "50%",
    left: "55%",
    transform: "translate(-50%,-50%)",
    padding: "20px",
    backgroundColor: "#fff",
    //borderRadius: '10px',
    color: "black",
    maxHeight: "420px",
    overflowX: "hiden",
    overflowY: "auto",
  };

  const toastStyle = {fontSize: '13.3px'};
  
export default function EditEvaluation({isOpen, positionId, closeEditEval}) {
       const url = process.env.REACT_APP_BACKEND_BASE_URL;
       const { user } = useAuth();
       const [startPosition, setStartPosition] = useState();
       const [endPosition, setEndPosition] = useState();
       const [phase, setPhase] = useState();
       const [isConform, setIsConform] = useState();
       const [description, setDescription] = useState();
       const [evaluations, setEvaluations] = useState([]);
       const [load, setLoad] = useState(false);

        
       const getEvaluations = useCallback(async () => {
        try {
            const response = await axios.get(`${url}/position/${positionId}`, {
                headers: {
                  Authorization: "Bearer " + user.access_token,
                },
              });
              setEvaluations(response)
              setStartPosition(response.data.startPosition)
              setEndPosition(response.data.endPosition)
              setPhase(response.data.phase)
              setIsConform(response.data.isConform)
              setDescription(response.data.description)
        } catch (error) {
            console.log(error);
        }

       })

      
        useEffect(() => {
          if (evaluations.length === 0) {
            getEvaluations();
          } 
        }, [getEvaluations]);
      
      
    
    const updateEvaluation = async (e) => {
        e.preventDefault();
        try {
            await axios.put(
                `${url}/position/edit/${positionId}`,
                {
                    startPosition: startPosition,
                    endPosition: endPosition,
                    phase: phase,
                    isConform: isConform == 1 ? true : false,
                    description: description
                },
                {
                  headers: {
                    Authorization: "Bearer " + user.access_token,
                  },
                }
              ); 
              toast.success('Actualizado', {style: toastStyle})
              closeEditEval()
        } catch (error) {
            toast.error(solveMessage(error), {style: {fontSize: '13.3px'}});
        }
    }
      
    if (isOpen) {
        return (
            <div className=" " style={BACKGROUND_STYLE}>
            <div className="text-start" id="modalContainer" style={MODAL_STYLE}>
              <span className="ms-1 " style={{ fontFamily: "Roboto" }}>
                 Avaliação:
              </span>
            <form className="text-start smollText" onSubmit={updateEvaluation}>
            <div className="row g-3"> 
            <div className="col-md">
              <label for="psicin" className="form-label">
                  Posição Inicial
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-sm"
                  id="psicin"
                  type="number"
                  placeholder=""
                  value={startPosition}
                  onChange={(e) => setStartPosition(e.target.value)}
                  //readonly="true"
                />
              </div>
              <div className="col-md">
               <label for="psicfin" className="form-label">
                  Posição Final
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-sm"
                  id="psicfin"
                  type="number"
                  placeholder=""
                  value={endPosition}
                  onChange={(e) => setEndPosition(e.target.value)}
                  //readonly="true"
                />
              </div>
              
            </div>
            <div className="row g-3"> 
            <div className="col-md">
               <label for="phase" className="form-label">
                  Fase
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-sm"
                  id="phase"
                  type="number"
                  placeholder=""
                  value={phase}
                  onChange={(e) => setPhase(e.target.value)}
                  //readonly="true"
                />
              </div>
              <div className="col-md">
              <label for="isconf" className="form-label">
                  Esta Correto ?
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <select id="isconf" 
                 className="form-select form-select-sm"
                  value={isConform}
                  onChange={(e) => setIsConform(e.target.value)}
                 
                >
                  <option value={1}>Sim</option>
                  <option value={0}>Não</option>
                </select>
              </div>
            </div>
            
              <div className="row"> 
               <div className="col-md">
                <label for="desc">Descrição:</label>
                <textarea className="form-control" 
                  rows="3" id="desc" name="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                >
               </textarea>
              </div>
            </div>

            <div className="clearfix mt-3">
              <button className="btn btn-sm float-end text-light sidItemL2">
                {load === true ? (
                    <span className="spinner-border spinner-border-sm"></span>
                ) : (
                    "Salvar"
                )}
              </button>

              <button
                preventScrollReset={false}
                type="button"
                className="btn btn-sm px-3 float-end me-2 borderbp ic2"
                onClick={closeEditEval}
              >
                Cancelar
              </button>
              
            </div>
            </form>
          </div>
          </div>
        );
    }

    return null;
  }