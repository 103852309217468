import { useNavigate, useParams, Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";

const EditLote = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [island_id, setIsland_id] = useState("");
  const [islands, setIslands] = useState([]);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();

  const getIslands = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/island/all`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setIslands(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [url, user.access_token, setIslands]);

  const getLoteById = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/lote/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setName(response.data[0].name);
      setIsland_id(response.data[0].island.id);
    } catch (error) {
      console.log(error);
    }
  }, [url, id, user.access_token, setName, setIsland_id]);

  useEffect(() => {
    getIslands();
    getLoteById();
  }, [getIslands, getLoteById]);

  const updateLote = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        `${url}/lote/${id}`,
        {
          name: name,
          island: island_id,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      if (response.statusText === "OK") {
        navigate("/listLote");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-sm pt-4 text-black ">
     
          <p className="small" style={{marginLeft: '139px'}}>
            <Link to='/listLote' className="mutec text-decoration-none ic">Lote  »  </Link>
            <span className="ic">Editar Lote</span>
          </p>
        
          <h5 className=" mb-3 floa-start ic" style={{ fontFamily: "Roboto", marginLeft: '139px'}}>
            Editar Lote
          </h5>
      
        <form className="p-3 mx-auto w-75 shadow-lg bg-white smollText" onSubmit={updateLote}>
          <div className="mb-3 mt-3">
            <label htmlFor="name" className="form-label labeFormMargin">
              Nome
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
            </label>
            <input
              className="form-control form-control-sm"
              id="name"
              type="text"
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-3 mt-3">
            <label htmlFor="island_id" className="form-label labeFormMargin">
              Ilha
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
            </label>
            <select
              id="island_id"
              className="form-select form-select-sm"
              value={island_id}
              onChange={(e) => setIsland_id(e.target.value)}
            >
              <option value=""></option>
              {islands.map((island) => (
                <option key={island.id} value={island.id}>
                  {island.name}
                </option>
              ))}
            </select>
          </div>
          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light sidItemL2"
            >
              Salvar
            </button>
            <Link
              to="/listLote"
              type="button"
              className="btn btn-sm px-3 float-end me-2 borderbp ic2"
            >
              Cancelar
            </Link>
          </div>
        </form>
      
    </div>
  );
};

export default EditLote;
