import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";

import { Map } from "../mapa/Mapa.js";

const EvaluationMap = () => {
  const { sectionId, initialDate, finalDate } = useParams();
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
  const [points, setPoints] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      const url = new URL(`${baseUrl}/position`);
      url.searchParams.append("sectionId", sectionId);
      url.searchParams.append("initialDate", initialDate);
      url.searchParams.append("finalDate", finalDate);

      const headers = { authorization: `Bearer ${user.access_token}` };

      const res = await axios.get(url.toString(), { headers });
      setPoints(res.data);
    })();
  }, []);

  return (
    <div className="container-sm pt-4">
      <h5 className="mb-3 ic2" style={{ fontFamily: "Roboto" }}>
        Mapa de Avaliações
      </h5>

      <Map
        style={{ width: "100%", height: "600px" }}
        evaluationPoints={points}
      />
    </div>
  );
};

export default EvaluationMap;
