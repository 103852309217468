import { useState } from "react";
import axios from "axios";
import { solveMessage } from "../utils";

const EditImage = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [file, setFile] = useState();

  const saveFile = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async () => {
    const imageId = 1;
    const formData = new FormData();
    formData.append("photo", file);
    try {
      const res = await axios.put(`${url}/image/upload/${imageId}`, formData);
      console.log(res.data.message);
    } catch (error) {
      console.log(solveMessage(error));
    }
  };

  return (
    <div className="container-sm p-5 my-5 text-black ">
      <h3 mx-auto p-2>
        Editar Imagem
      </h3>
      <form className="border p-3">
        <input type="file" name="photo" onChange={saveFile} />
        <button
          type="button"
          className="btn btn-success btn-block"
          onClick={uploadFile}
        >
          Upload
        </button>
      </form>
    </div>
  );
};

export default EditImage;
