import { useParams, Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { solveMessage } from "../utils";

const ViewUser = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { idUser } = useParams();
  const { user } = useAuth();
  const [usr, setUsr] = useState([]);
  
  const getUser = useCallback(async () => {
    
    try {
      const response = await axios.get(`${url}/users`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });

      const us = response.data.filter((insp) => insp.id === idUser);
      setUsr(us)
    } catch (error) {
      console.log(solveMessage(error));
    }
  }, [
    setUsr
  ]);

  useEffect(() => {
    getUser()
  }, [getUser]);

 console.log(usr);
  return (
    <div className="container-sm pt-4 text-black">
    <div className="mx-auto w-75">
      <p className="small" style={{marginLeft: '105px'}}>
      <Link to='/listUser' className="mutec text-decoration-none ic">Utilizadores  »  </Link>
      <span className="ic">Visualizar Utilizador</span>
      </p>

        <h5 className=" mb-3 ic" style={{ fontFamily: "Roboto", marginLeft: '105px'}}>
          Visualizar Utilizador
        </h5>

      <div className="bg-white shadow-lg mb-1 pt-3 pb-3 mx-auto w-75 ps-5">
        <ul className="list-group">
          <li className="list-group-item d-flex justify-content-between align-items-center border-0">
            <span>Primeiro Nome</span>
            <span className="mutec" style={{marginRight: '60px'}}> {usr.length ? usr[0].firstName : '...' } </span>
          </li><hr className="ic2" style={{marginRight: "60px"}}/>
          <li className="list-group-item d-flex justify-content-between align-items-center border-0">
            <span>Ultimo Nome</span>
            <span className="mutec" style={{marginRight: '60px'}}> { usr.length ? usr[0].lastName : '...'} </span>
          </li><hr className="ic2" style={{marginRight: "60px"}}/>
          <li className="list-group-item d-flex justify-content-between align-items-center border-0">
            <span>BI/CNI</span>
            <span className="mutec" style={{marginRight: '60px'}}> { usr.length ? usr[0].bi : '...'} </span>
          </li><hr className="ic2" style={{marginRight: "60px"}}/>
          <li className="list-group-item d-flex justify-content-between align-items-center border-0">
            <span>Data Nascimento</span>
            <span className="mutec" style={{marginRight: '60px'}}> { usr.length ? usr[0].birthDate : '...'} </span>
          </li><hr className="ic2" style={{marginRight: "60px"}}/>
          <li className="list-group-item d-flex justify-content-between align-items-center border-0">
            <span>email</span>
            <span className="mutec" style={{marginRight: '60px'}}> { usr.length ? usr[0].email : '...'} </span>
          </li><hr className="ic2" style={{marginRight: "60px"}}/>
          <li className="list-group-item d-flex justify-content-between align-items-center border-0">
            <span>Telefone</span>
            <span className="mutec" style={{marginRight: '60px'}}> { usr.length ? usr[0].phone : '...'} </span>
          </li><hr className="ic2" style={{marginRight: "60px"}}/>
        </ul>
      </div>
    </div> 
  </div>
  );
};

export default ViewUser;
