import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import List from "../List";
import axios from "axios";
import { formatDateTime } from "../utils";

const ListIsland = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [islands, setIslands] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const columnTitles = [
    { key: "name", value: "Nome" },
    { key: "created_at", value: "Data Criação" },
  ];

  const getIslands = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/island/all`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      const islandsWithFormatedData = response.data.map((v) => ({
        ...v,
        created_at: formatDateTime(v.created_at),
      }));
      setIslands(islandsWithFormatedData);
      setFiltered(islandsWithFormatedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [url, user.access_token]);

  useEffect(() => {
    getIslands();
  }, [getIslands]);

  const filter = (e) => {
    const pattern = RegExp(e.target.value, "i");
    const results = islands.filter((island) => island.name.match(pattern));
    setFiltered(results);
  };

  const deleteIsland = async (id) => {
    try {
      await axios.delete(`${url}/island/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      getIslands();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <List
      title="Ilhas"
      columnTitles={columnTitles}
      items={filtered}
      filter={filter}
      originLink="/listIsland#"
      deleteItem={deleteIsland}
      viewBaseLink="/viewIsland"
      editBaseLink="/editIsland"
      newLink="/addIsland"
      loading={loading}
    />
  );
};

export default ListIsland;
