import React, { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";

const AutoEdit = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [confNewPassword, setConfNewPassword] = useState("");
  const [feedMessage, setFeedMessage] = useState("default");
  const [newPassword, setNewPassword] = useState("");
  const [telephone, setTelephone] = useState("");
  const [oldPassw, setOldPassw] = useState("");
  const [address, setAddress] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${url}/user/byId/${user.profile.sub}`,
          {
            headers: {
              Authorization: "Bearer " + user.access_token,
            },
          }
        );
        setTelephone(response.data[0].telephone);
        setAddress(response.data[0].address);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [url, user.profile.sub, user.access_token, setTelephone, setAddress]);

  const updateUser = async (e) => {
    e.preventDefault();
    setFeedMessage("");
    try {
      const response = await axios.put(
        `${url}/user/autoUpdate/${user.profile.sub}`,
        {
          telephone: telephone,
          address: address,
          oldPassw: oldPassw,
          password: newPassword,
          confPassword: confNewPassword,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setFeedMessage(response.statusText);
      console.log(response.data.message);
    } catch (error) {
      setFeedMessage(error.response.statusText);
      console.log(error);
    }
  };

  return (
    <div className="container-sm pt-4 text-black ">
      <h4 className="text-left mb-1 pt-4 ms-2" style={{ fontFamily: "Roboto" }}>
        Editar Perfil
      </h4>
      <div className="detail shadow-lg ms-2 me-2">
        <form className=" p-3 mx-auto w-75" onSubmit={updateUser}>
          <div className="row">
            <div className="col">
              <label for="address" className="form-label">
                Endereço
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control"
                id="address"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="col">
              <label for="telephone" className="form-label">
                Telefone
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control"
                id="telephone"
                type="text"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label for="newPassword" className="form-label">
                Password
                <span
                  className="text-danger"
                  style={{ fontSize: "22px" }}
                ></span>
              </label>
              <input
                className="form-control"
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="col">
              <label for="confNewPassword" className="form-label">
                Confirmar Password
                <span
                  className="text-danger"
                  style={{ fontSize: "22px" }}
                ></span>
              </label>
              <input
                className="form-control"
                id="confNewPassword"
                type="password"
                placeholder=""
                value={confNewPassword}
                onChange={(e) => setConfNewPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label for="oldPassw" className="form-label">
                Antigo Password
                <span
                  className="text-danger"
                  style={{ fontSize: "22px" }}
                ></span>
              </label>
              <input
                className="form-control"
                id="oldPassw"
                type="password"
                placeholder=""
                value={oldPassw}
                onChange={(e) => setOldPassw(e.target.value)}
              />
            </div>
            <div className="col"></div>
          </div>
          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light"
              style={{ backgroundColor: "#EF7800" }}
            >
              {feedMessage === "" ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AutoEdit;
