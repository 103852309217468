import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { formatDateTime } from "../utils";
import List from "../List";
import axios from "axios";

const ListResponsable = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [responsables, setResponsable] = useState([]);
  const { user } = useAuth();
  const columnTitles = [
    { key: "name", value: "Nome" },
    { key: "funct", value: "Função" },
    { key: "email", value: "Email" },
    { key: "telephone", value: "Telefone" },
    { key: "resicende", value: "Residência" },
    { key: "created_at", value: "Data Criação" },
  ];

  const getResponsables = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/responsable`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      const data = response.data.map((v) => ({
        ...v,
        created_at: formatDateTime(v.created_at),
      }));
      setResponsable(data);
      setFiltered(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [url, user.access_token]);

  useEffect(() => {
    getResponsables();
  }, [getResponsables]);

  const filter = (e) => {
    const pattern = RegExp(e.target.value, "i");
    const results = responsables.filter(
      (responsable) =>
        responsable.name.match(pattern) ||
        responsable.funct.match(pattern) ||
        responsable.email.match(pattern) ||
        responsable.resicende.match(pattern)
    );
    setFiltered(results);
  };

  const deleteResponsable = async (id) => {
    try {
      await axios.delete(`${url}/responsable/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      await getResponsables();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <List
      title="Responsável"
      columnTitles={columnTitles}
      items={filtered}
      filter={filter}
      originLink="/listResponsable#"
      newLink="/addResponsable"
      viewBaseLink="/viewResponsable"
      editBaseLink="/editResponsable"
      deleteItem={deleteResponsable}
      loading={loading}
    />
  );
};

export default ListResponsable;
