import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { Map } from "../mapa/Mapa.js";
import axios from "axios";

const EditEvaluationMap = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { id } = useParams();
  const { user } = useAuth();
  const [points, setPoints] = useState([]);
  const [evaluation, setEvaluation] = useState({});
  const [edited, setEdited] = useState(false);

  const changePoints = (data) => {
    setPoints(data);
    setEdited(true);
  };

  const getPoints = async () => {
    const res1 = await axios.get(`${url}/evaluation/${id}/positions`, {
      headers: { authorization: `Bearer ${user.access_token}` },
    });
    if (res1.status === 200) setPoints(res1.data);
  };

  const handleCancel = () => {
    getPoints();
    setEdited(false);
  };

  const handleSave = async () => {
    await axios.put(
      `${url}/evaluation/${id}/positions`,
      { points },
      {
        headers: { authorization: `Bearer ${user.access_token}` },
      }
    );

    setEdited(false);
  };

  useEffect(() => {
    getPoints();
    (async () => {
      const res2 = await axios.get(`${url}/evaluation/byId/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      if (res2.status === 200) {
        setEvaluation(res2.data[0]);
      }
    })();
  }, [url, user.access_token, id]);

  return (
    <div className="container-sm pt-4 ">
      <div className="w-75 mx-auto">
        <h5 className="mb-2 clearfix" style={{ fontFamily: "Roboto" }}>
          <span className="fw-lighter">
            Avaliação
            <small className="text-muted ms-2">
              {evaluation?.positions?.length > 0 &&
                evaluation?.positions[0]?.phase}
              º Mez
            </small>
          </span>
          <span className="fw-lighter ms-2">
            Contrato
            <small className="text-muted ms-2">
              {evaluation?.section?.contract?.name}
            </small>
          </span>
          <span className="fw-lighter ms-1">
            Estrada
            <small className="text-muted ms-2">
              {evaluation?.section?.road?.codigo}/
              {evaluation?.section?.road?.extensao}Km
            </small>
          </span>
          <span className="fw-lighter ms-1">
            Troço
            <small className="text-muted ms-2">
              {evaluation?.section?.kmInicial} A {evaluation?.section?.KmFinal}{" "}
              Km
            </small>
          </span>
        </h5>

        <Map
          editable={true}
          evaluationPoints={points}
          setEvaluationPoints={changePoints}
        />

        <div className="clearfix mt-3">
          <button
            className="btn btn-sm float-end text-light sidItemL2"
            disabled={!edited}
            onClick={handleSave}
          >
            Salvar
          </button>

          <button
            type="button"
            className="btn btn-sm px-3 float-end me-2 borderbp ic2"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditEvaluationMap;
