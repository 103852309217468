import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import {toast } from 'react-toastify';
import { solveMessage } from "../utils";

const AddZone = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [islands, setIslands] = useState([]);
  const [name, setName] = useState("");
  const [island_id, setIsland_id] = useState("");
  const { user } = useAuth();
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const toastStyle = {fontSize: '13.3px'};

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${url}/island/all`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });
        setIslands(response.data);
      } catch (error) {
        console.log(solveMessage(error));
      }
    })();
  }, [url, user.access_token]);

  const saveZone = async (e) => {
    e.preventDefault();
    setLoad(true)
    try {
      const response = await axios.post(
        `${url}/zone`,
        {
          name: name,
          island: island_id,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setLoad(false)
      toast.success('Adicionado com Sucesso', {style: toastStyle});
      navigate("/listZone");
    } catch (error) {
      setLoad(false)
      toast.error(solveMessage(error), {style: toastStyle});
    }
  };

  return (
    <div className="container-sm pt-4 text-black ">
      <div className="mx-auto w-75">
      <p className="small" style={{marginLeft: '105px'}}>
          <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
          <Link to='/listZone' className="mutec text-decoration-none ic">Zona  »  </Link>
          <span className="ic">Criar Zona</span>
      </p>
      <h5 className="mb-3 ic" style={{ fontFamily: "Roboto", marginLeft: '105px' }}>
        Criar Zona
      </h5>
      
        <form className=" p-3 mx-auto w-75 shadow-lg bg-white smollText" onSubmit={saveZone}>
          <div className="mb-3 mt-3">
            <label for="name" className="form-label labeFormMargin">
              Nome
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
            </label>
            <input
              className="form-control form-control-sm"
              id="name"
              type="text"
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mb-3 mt-3">
            <label for="island_id" className="form-label labeFormMargin">
              Ilha
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
            </label>
            <select
              id="island_id"
              className="form-select form-select-sm"
              value={island_id}
              onChange={(e) => setIsland_id(e.target.value)}
              >
              <option value="" selected></option>
              {islands.map((island) => (
                <option value={island.id}>{island.name}</option>
              ))}
            </select>
          </div>

          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light sidItemL2"
            >
              {load === true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>

            <Link
              to="/listZone"
              type="button"
              className="btn btn-sm px-3 float-end me-2 borderbp ic2"
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div> 
    </div>
  );
};

export default AddZone;
