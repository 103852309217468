import { useState, useEffect, useCallback, useRef} from "react";
import { DownloadTableExcel, useDownloadExcel } from 'react-export-table-to-excel';
import { Link, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import axios from "axios";


import {
  inconformPerc,
  conformPerc,
  mensalValue,
  desconto,
  recebimentoMensal,
  differenceMonths,
} from "../utils.js";
import EditEvaluation from "./EditEvaluation.js";

const Evaluation = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [positionsEvaluation, setPositionEvaluation] = useState([]);
  const [roadsContract, setRoadsContract] = useState([]);
  const [companyEmail, setCompanyEmail] = useState("");
  const [phaseEval, setPhaseEval] = useState(1);
  const [sectionUserId, setSectionUserId] = useState("");
  const [usr, setUser] = useState("");
  const [contrato, setContrato] = useState([]);
  const [estrada, setEstrada] = useState("");
  const [sectionCod, setSectionCod] = useState("");
  const [sectionKminic, setSectionKminic] = useState();
  const [sectionKmFin, setSectionKmFin] = useState();
  const [evaluationParameter, setEvaluationParameter] = useState();
  const [load, setLoad] = useState(false);
  const [sendLoad, setSendLoad] = useState(false);
  const { sectionId } = useParams();
  const { initialDate } = useParams();
  const { finalDate } = useParams();
  const { user } = useAuth();
  const tableRef = useRef(null);
  const [file, setFile] = useState();
  const [sending, setSending] = useState(false);


  const getEvaluation = useCallback(async (v) => {
    const phas =  v ? v : 1
    setPhaseEval(phas);
    setLoad(true);
    try {
      const response = await axios.get(
        `${url}/evaluation/evaluationPosit/${sectionId}/${phas}`,
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );

      if (response.data.length > 0) {
        setPositionEvaluation(response.data);
        setContrato(response.data[0].section.contract);
        setCompanyEmail(response.data[0].section.contract.company.email);
        setEstrada(response.data[0].section.road.codigo);
        setRoadsContract(response.data[0].section.contract.roads);
        setSectionUserId(response.data[0].section.userId);
        setSectionCod(response.data[0].section.cod);
        setSectionKmFin(response.data[0].section.KmFinal)
        setSectionKminic(response.data[0].section.kmInicial)
        setEvaluationParameter(response.data[0].section.evaluationParameter);
      } else {
        setPositionEvaluation("");
        setContrato("");
        setCompanyEmail("");
        setEstrada("");
        setRoadsContract("");
        setSectionCod("");
        setSectionKmFin("")
        setSectionKminic("")
        setEvaluationParameter("");
      }
      setLoad(false)
    } catch (error) {
      setLoad(true)
      console.log(error);
    }
  }, [url, sectionId, user.access_token]);

  const getInspector = useCallback(async () => {
    try {
      const res = await axios.get(`${url}/keycloak/users/${sectionUserId}`, {
        headers: { authorization: `Bearer ${user.access_token}` },
      });
      setUser(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [url, user.access_token]);

  useEffect(() => {
    getEvaluation();
    getInspector();
  }, [getEvaluation, getInspector]);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `contrato:${contrato.name}_estrada:${estrada}`,
    sheet: 'Avaliação'
   })

   const exportFile = () => {
     setSending(true);
     setTimeout(onDownload, 500);
  };
  
  const saveFile = (e) => {
    setFile(e.target.files[0]);
  };

  const sendEvaluation = async () => {
    setSending(true);
    setSendLoad(true)
    const formData = new FormData();
    formData.append("excsel", file);
    try {
      const response = await axios.post(
        `${url}/evaluation/send/${companyEmail}/${phaseEval}`, formData,
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      alert(response.data.message);
      setSending(false);
      setSendLoad(false)
    } catch (error) {
      setSending(false);
      setSendLoad(false)
      console.log(error);
    }
  };

  let PhaseButton = [];
  const months = differenceMonths(initialDate, finalDate);
    if (months) {
      for (let i = 1; i <= months; i++) {
        PhaseButton.push(
        <option value={i}>{i + "º Mez"}</option>
        );
      }
    }

  const getEvaluationPhase = async(e) => {
    const phase = await e.target.value;
    getEvaluation(phase)
  }; 


  const inconformCount = (positions) => {
    var count = 0;
    positions.map((position, index) => {
      if (position.isConform === false && position.status === true) {
        count = count + evaluationParameter;
      }
    });

    return count;
  };
  
  return (
    <div className="container-sm pt-4">
      <p className="small" style={{ marginLeft: "" }}>
        <Link to="/" className="mutec text-decoration-none ic">
          Ver Contrato »{" "}
        </Link>
        <span className="ic">Avaliação</span>
      </p>
      <h5 className="mb-3 ic2" style={{ fontFamily: "Roboto" }}>
        Contrato
        <small className="text-muted ms-2">{contrato.name}</small>
      </h5>

      <div className="mb-2 clearfix">
        <div className="dropdown float-start">
          <select className="form-select form-select-sm"
            onChange={getEvaluationPhase}
          >
            {PhaseButton}
          </select>
        </div>
        
        {positionsEvaluation.length > 0 && (
          <>
            <button className="btn btn-sm float-start text-light sidItemL2 ms-1"
              style={{fontSize: '13px'}}
              onClick={exportFile}
            > 
              Gerar excel 
            </button>
           
            <input
            className="form-control form-control-sm w-25 float-start ms-1"
            id="formFileSm"
            type="file"
            onChange={saveFile}
            style={{width: '5px'}}
          />
            <button
              onClick={sendEvaluation}
              className="btn btn-sm float-start text-light sidItemL2 ms-1"
              style={{fontSize: '13px'}}
            >
              {sendLoad === true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                <>
                <i className="fa fa-paper-plane pe-1 "></i>
                <span>Enviar</span>
                </>
              )}
              
            </button>
            </> 
        )}
        <span className=" float-end ms-3 ic2">
          Troço
          <small className="text-muted ms-2 me-2">{sectionKminic} A {sectionKmFin} Km</small>
        </span>
        <span className="float-end ic2">
          Estrada
          <small className="text-muted ms-2 me-2">{estrada}</small>
        </span>
      </div>
     
      <table className="table table-bordered table-sm shadow-lg p-3 bg-white align-middle" 
        style={{textAlign: 'center'}}
        ref={tableRef}
      >
      {load && <caption className="spinner-border ic2 ms-2"></caption>}
        <thead className="mediunText">
          <tr className="">
            <th>Nº</th>
            <th>Serviço</th>
            <th>SubServiço</th>
            {sending === false && <th>Anomalias</th>}
            <th>Km Nao Conforme</th>
            <th>% Nao Conforme</th>
            <th>% Conforme</th>
            <th>Ponderação</th>
            { sending === false && <th>Ações</th> }
          </tr>
        </thead>
        <tbody className="smollText border border-top-0">
          {positionsEvaluation.length &&
            
             positionsEvaluation.map((evaluation, index) => (
                <tr key={evaluation.id}>
                  <td>{index + 1}</td>
                  <td>{evaluation.typeservice.name}</td>
                  <td>
                    {evaluation.typeservice.name !== "Pavimento da Via"
                      ? ""
                      : evaluation.criterion.name}
                  </td>
                 { sending === false &&
                  <td className="">
                    {inconformCount(evaluation.positions) > 0 ? (
                      <i className="fas fa-times text-danger"></i>
                    ) : (
                      <i className="fa fa-check text-success"></i>
                    )}
                  </td>
                 }
                  <td>{inconformCount(evaluation.positions)}</td>
                  <td>
                    {inconformPerc(
                      inconformCount(evaluation.positions),
                      evaluation.section.KmFinal,
                      evaluation.section.kmInicial
                    ) + " %"}
                  </td>
                  <td>
                    {conformPerc(
                      inconformCount(evaluation.positions),
                      evaluation.section.KmFinal,
                      evaluation.section.kmInicial
                    ) + " %"}
                  </td>
                  <td>
                    {inconformCount(evaluation.positions) > 0
                      ? evaluation.ponderacao
                      : 0}
                  </td>
                {sending === false && 
                  <td className="">
                    <Link
                      to={`/editEvaluation/${evaluation.id}/map`}
                      className="btn btn-sm fas fa-map-marked-alt p-2 ic2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Mapa"
                    ></Link>
                    <Link
                      to={`/viewEvaluation/${evaluation.id}`}
                      className="btn btn-sm fas fa-eye p-2 ic2 smollText"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Vizualizar"
                    ></Link>
                  </td>
                }
                </tr>
              )
            ) 
            }
          </tbody>
          { sending === true &&
          <thead className="smollText">
            <tr>
              <th colspan="4" className="ic">Proposta Mensal</th>
              <th colspan="5" className="mutec">{mensalValue(
                  contrato.initialDate,
                  contrato.finalDate,
                  roadsContract
                ) + "$00"}</th>
            </tr>
            <tr >
              <th colspan="4" className="ic">% Desconto de Valor Mensal de Manutenção</th>
              <th colspan="5" className="mutec">{desconto(positionsEvaluation, evaluationParameter) + "%"}</th>
            </tr>
            <tr>
              <th colspan="4" className="ic">Recebimento Mensal</th>
              <th colspan="5" className="mutec">
                {recebimentoMensal(
                  positionsEvaluation,
                  evaluationParameter,
                  contrato.initialDate,
                  contrato.finalDate,
                  roadsContract
                ) + "$00"}
                </th>
            </tr>
          </thead>
          }
        </table>

      {positionsEvaluation.length === 0 ? (
        ""
      ) : (
        <div className="bg-white" style={{ marginTop: "-15px" }}>
          <ul className="list-group pt-3">
            <li className="list-group-item border-0">
              <span className="ic">Proposta Mensal</span>
              <br />
              <span className="mutec">
                {mensalValue(
                  contrato.initialDate,
                  contrato.finalDate,
                  roadsContract
                ) + "$00"}
              </span>
            </li>
            <li className="list-group-item border-0">
              <span className="ic">
                % Desconto de Valor Mensal de Manutenção
              </span>
              <br />
              <span className="mutec">
                {desconto(positionsEvaluation, evaluationParameter) + "%"}
              </span>
            </li>
            <li className="list-group-item border-0">
              <span className="ic">Recebimento Mensal</span>
              <br />
              <span className="mutec">
                {recebimentoMensal(
                  positionsEvaluation,
                  evaluationParameter,
                  contrato.initialDate,
                  contrato.finalDate,
                  roadsContract
                ) + "$00"}
              </span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Evaluation;
