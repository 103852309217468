import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { formatDateTime } from "../utils";
import List from "../List";
import axios from "axios";

const ListLote = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lotes, setLotes] = useState([]);
  const { user } = useAuth();
  const columnTitles = [
    { key: "name", value: "Nome" },
    { key: "island", value: "Ilha" },
    { key: "created_at", value: "Data Criação" },
  ];

  const getLotes = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/lote`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      const data = response.data.map((v) => ({
        ...v,
        created_at: formatDateTime(v.created_at),
        island: v.island.name,
      }));
      setLotes(data);
      setFiltered(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [url, user.access_token, setLotes, setFiltered]);

  useEffect(() => {
    getLotes();
  }, [getLotes]);

  const filter = (e) => {
    const pattern = RegExp(e.target.value, "i");
    const results = lotes.filter(
      (lote) => lote.name.match(pattern) || lote.island.match(pattern)
    );
    setFiltered(results);
  };

  const deleteLote = async (id) => {
    try {
      await axios.delete(`${url}/lote/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      await getLotes();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <List
      title="Lote"
      columnTitles={columnTitles}
      items={filtered}
      filter={filter}
      originLink="/listLote#"
      newLink="/addLote"
      viewBaseLink="/viewLote"
      editBaseLink="/editLote"
      deleteItem={deleteLote}
      loading={loading}
    />
  );
};

export default ListLote;
