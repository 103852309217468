import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import {toast } from 'react-toastify';
import { solveMessage } from "../utils";

const BACKGROUND_STYLE = {
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: 'rgb(0,0,0, 0.7)',
    //sobre põe uma tela sobre outra
    zIndex: '1000',
    overflowY: "auto"
  }
 
  const MODAL_STYLE = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    padding: '20px',
    backgroundColor: '#fff',
    //borderRadius: '10px',
    color: 'black',
    maxHeight: '420px',
    overflowX: 'hiden',
    overflowY: 'auto',
    width: '450px'
  }

  const toastStyle = {fontSize: '13.3px'};

  export default function ModalAddRoad(
    {isOpen, roads, contractId, setModalAddRoadClose, getSaved , isExternalContract}
  ){
     const url = process.env.REACT_APP_BACKEND_BASE_URL; 
     const { user } = useAuth();
     const [road_id, setRoad_id] = useState("");
     const [load, setLoad] = useState(false);
     console.log("setState",isExternalContract)

     const save = async (e) => {
        setLoad(true)
        e.preventDefault();
        if (road_id === "") {
          toast.error("Estrada Não Pode Ser Vazio", {style: toastStyle});
          setLoad(false)
          return;
        }
  
        try {
         const response = await axios.post(
            `${url}/contract/sectCont`,
            {
              contractId: Number(contractId), // ou +contractId
              roadId: Number(road_id),
              isExternalContract: !!isExternalContract 
            },
            {
              headers: {
                Authorization: "Bearer " + user.access_token,
              },
            }
          );
           setLoad(false)
           toast.success('Adicionado com Sucesso', {style: toastStyle});
           getSaved();
        } catch (error) {
            setLoad(false)
          toast.error(solveMessage(error), {style: toastStyle});
        }
  
      }

    if (isOpen) {
    return (
            <div className="" style={BACKGROUND_STYLE}>
              
              <div className="" id="modalContainer " style={MODAL_STYLE}>
                <h5 className="ms-3" style={{ fontFamily: "Roboto"}}>
                    Adicionar Estrada
                </h5>
              <form  onSubmit={save} >
              <div className="card shadow-lg m-3 smollText">
                  <div className="card-body">
                 
                  <label htmlFor="road_cod" className="form-label">
                    Código
                    <span className="text-danger" style={{ fontSize: "22px" }}>
                        *
                    </span>
                  </label>
                  <select
                      id="road_cod"
                      className="form-select form-select-sm"
                       value={road_id}
                       onChange={(e) => setRoad_id(e.target.value)}
                       style={{width: ''}}>
                      <option value=""></option>
                      { 
                       roads.map((road) => (
                          <option key={road.id} value={road.id}>
                          {road.codigo}
                          </option>
                      ))}
                  </select>
                
                </div>
                </div>
                <div className="clearfix me-3">
                    <button
                        className="btn btn-sm ms-1 float-end text-light sidItemL2"
                        >
                        { 
                            load === true ? 
                            <span className="spinner-border spinner-border-sm"></span> : 
                            'Salvar'
                        } 
                    </button>

                   <button
                    preventScrollReset={false}
                    type="button"
                    className="btn btn-sm px-3 float-end borderbp ic2"
                    
                    onClick={setModalAddRoadClose}
                    >
                    Cancelar
                    </button>
                </div>    
                </form>
                </div>
            </div>
    )
    }

    return null;
  }