import { useParams, useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import {toast } from 'react-toastify';
import { solveMessage } from "../utils";

const EditIsland = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const [load, setLoad] = useState(false);
  const toastStyle = {fontSize: '13.3px'};


  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${url}/island/byid/${id}`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });

        setName(response.data[0].name);
      } catch (error) {
        console.log(solveMessage(error));
      }
    })();
  }, [url, id, user.access_token]);

  const updateIsland = async (e) => {
    e.preventDefault();
    setLoad(true)

    try {
      const response = await axios.put(
        `${url}/island/${id}`,
        { name: name },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
        setLoad(false)
        toast.success('Actualizado', {style: toastStyle});
        navigate("/listIsland");
    } catch (error) {
      setLoad(false)
      toast.error(solveMessage(error), {style: toastStyle});
    }
  };

  return (
    <div className="container-sm pt-4 text-black">
      <div className="mx-auto w-75">     
        <p className="small" style={{marginLeft: '105px'}}>
          <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
          <Link to='/listIsland' className="mutec text-decoration-none ic">Ilha  »  </Link>
          <span className="ic">Editar Ilha</span>
        </p>
      
        <h5 className="mb-3 floa-start titlemv ic" style={{ fontFamily: "Roboto", marginLeft: '105px' }}>
          Editar Ilha
        </h5>
      
        <form className=" p-3 mx-auto w-75 bg-white smollText" onSubmit={updateIsland}>
          <div className="mb-3 mt-3">
            <label htmlFor="name" className="form-labe labeFormMarginl">
              Nome
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
            </label>
            <input
              className="form-control form-control-sm"
              id="name"
              type="text"
              placeholder="Nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light sidItemL2"
            >
              {load === true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>

            <Link
              to="/listIsland"
              type="button"
              className="btn btn-sm px-3 float-end me-2 borderbp ic2"
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditIsland;
