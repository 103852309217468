import { useAuth } from "react-oidc-context";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { formatDateTime } from "../utils";
import List from "../List";
import { toast } from "react-toastify";
const ListRoad = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roads, setRoads] = useState([]);
  const { user } = useAuth();
  const toastStyle = { fontSize: '13.3px' };
  const columnTitles = [
    { key: "codigo", value: "Código" },
    { key: "name", value: "Nome" },
    { key: "classificcao", value: "Classificação" },
    { key: "extensao", value: "Extensão" },
    { key: "zone", value: "Zona" },
    { key: "lote", value: "Lote" },
    { key: "created_at", value: "Data Criação" },
  ];

 const getRoads = useCallback(async () => {
  try {
    const response = await axios.get(`${url}/road`, {
      headers: {
        Authorization: "Bearer " + user.access_token,
      },
    });

    // Mapeia os dados e formata a data
    const data = response.data.map((v) => {
      const createdAt = new Date(v.created_at).toISOString(); // Converte para ISO
      const formattedDate = createdAt.replace("T", " ").split(".")[0]; // Remove o "T" e a parte dos segundos
      return {
        ...v,
        created_at: formattedDate, // Exibe a data sem segundos
        lote: v.lote.name,
        zone: v.zone.name,
        extensao: `${v.extensao} Km`,
      };
    });

    // Ordena os dados pela data de criação (created_at)
    data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Ordem crescente
    // Se você quiser ordem decrescente, use: `data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));`

    setRoads(data);
    setFiltered(data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
}, [url, user.access_token]);


  useEffect(() => {
    getRoads();
  }, [getRoads]);

  const filter = (e) => {
    const pattern = RegExp(e.target.value, "i");
    const results = roads.filter(
      (road) =>
        road.codigo.match(pattern) ||
        road.name.match(pattern) ||
        road.classificcao.match(pattern) ||
        road.extensao.match(pattern) ||
        road.zone.match(pattern) ||
        road.lote.match(pattern)
    );
    setFiltered(results);
  };

  const deleteRoad = async (id) => {
    try {
      await axios.delete(`${url}/road/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      toast.success("Eliminado com sucesso", { style: toastStyle });
      await getRoads();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao Eliminar", { style: toastStyle });
    }
  };

  return (
    <List
      title="Estrada"
      columnTitles={columnTitles}
      items={filtered}
      filter={filter}
      originLink="/listRoad#"
      newLink="/addRoad"
      viewBaseLink="/viewRoad"
      editBaseLink="/editRoad"
      deleteItem={deleteRoad}
      loading={loading}
    />
  );
};

export default ListRoad;
