import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import {toast } from 'react-toastify';
import { solveMessage } from "../utils";

const AddCompany = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  
  const [responsable_id, setResponsable_id] = useState("");
  //const [responsables, setResponsable] = useState([]);
  const [telephone, setTelephone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [nif, setNif] = useState("");

  const [telephoner, setTelephoner] = useState("");
  const [resicende, setResicende] = useState("");
  const [funct, setFunct] = useState("");
  const [emailr, setEmailr] = useState("");
  const [namer, setNamer] = useState("");
  const [load, setLoad] = useState(false);

  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${url}/responsable`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });
        //setResponsable(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [url, user.access_token]);

  const saveCompany = async (e) => {
    e.preventDefault();
    setLoad(true)
    try {
      const response = await axios.post(
        `${url}/company`,
        {
          name: name,
          nif: nif,
          address: address,
          email: email,
          telephone: telephone,
          responsable: responsable_id,

          namer: namer,
          funct: funct,
          emailr: emailr,
          telephoner: telephoner,
          resicende: resicende,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
       setLoad(false)
       toast.success('Adicionado com Sucesso', {style: {fontSize: '13.3px'}});
       navigate("/listCompany");
    } catch (error) {
      setLoad(false)
      toast.error(solveMessage(error), {style: {fontSize: '13.3px'}});
    }
  };

  return (
    <div className="container-sm pt-4 text-black">
      <div className="mx-auto w-75">
        <p className="small" style={{marginLeft: '105px'}}>
          <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
          <Link to='/listCompany' className="mutec text-decoration-none ic">Empresa  »  </Link>
          <span className="ic">Criar Empresa</span>
        </p> 
        <h5 className="mb-3 ic" style={{ fontFamily: "Roboto", marginLeft: '105px'}}>
          Criar Empresa
        </h5>
      
        <form className="p-3 mx-auto w-75 shadow-lg bg-white smollText" onSubmit={saveCompany}>
          <p className="mediunText" style={{color:"#122C48"}}>Empresa:</p><hr className="ic"></hr>
          <div className="row g-3">
            <div className="col-md">
              <label htmlFor="name" className="form-label labeFormMargin">
                Nome
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="name"
                type="text"
                placeholder=""
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label htmlFor="nif" className="form-label labeFormMargin">
                Nif
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="nif"
                type="text"
                placeholder=""
                value={nif}
                onChange={(e) => setNif(e.target.value)}
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md">
              <label htmlFor="address" className="form-label labeFormMargin">
                Endereço
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="address"
                type="text"
                placeholder=""
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label htmlFor="email" className="form-label labeFormMargin">
                Email
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="email"
                type="text"
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md">
              <label htmlFor="telephone" className="form-label labeFormMargin">
                Telefone
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="Telefone"
                type="text"
                placeholder=""
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
            </div>
            <div className="col-md">
            </div>
          </div>

           <p className="mt-4 mediunText" style={{color:"#122C48"}}>Responsavel:</p><hr></hr>

          <div className="row g-3">
            <div className="col-md">
              <label for="name" className="form-label labeFormMargin">
                Nome
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="name"
                type="text"
                placeholder=""
                value={namer}
                onChange={(e) => setNamer(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label for="funct" className="form-label labeFormMargin">
                Função
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="funct"
                type="text"
                placeholder=""
                value={funct}
                onChange={(e) => setFunct(e.target.value)}
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md">
              <label for="email" className="form-label labeFormMargin">
                Email
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="email"
                type="text"
                placeholder=""
                value={emailr}
                onChange={(e) => setEmailr(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label for="telephone" className="form-label labeFormMargin">
                Telefone
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="telephone"
                type="text"
                placeholder=""
                value={telephoner}
                onChange={(e) => setTelephoner(e.target.value)}
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md">
              <label for="resicende" className="form-label labeFormMargin">
                Residência
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="resicende"
                type="text"
                placeholder=""
                value={resicende}
                onChange={(e) => setResicende(e.target.value)}
              />
            </div>
            <div className="col-md"></div>
          </div>

          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light sidItemL2"
            >
              {load === true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>
            <Link
              to="/listCompany"
              type="button"
              className="btn btn-sm px-3 float-end me-2 borderbp ic2"
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCompany;
