import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {toast } from 'react-toastify';
import { solveMessage } from "../utils";

const EditZone = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [name, setName] = useState("");
  const [island_id, setIsland_id] = useState("");
  const { id } = useParams();
  const [islands, setIslands] = useState([]);
  const { user } = useAuth();
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const toastStyle = {fontSize: '13.3px'};

  const getIslands = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/island/all`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setIslands(response.data);
    } catch (error) {
      console.log(solveMessage(error));
    }
  }, [url, user.access_token, setIslands]);

  const getZoneById = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/zone/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setName(response.data[0].name);
      setIsland_id(response.data[0].island.id);
    } catch (error) {
      console.log(solveMessage(error));
    }
  }, [url, id, user.access_token, setName, setIsland_id]);

  useEffect(() => {
    getIslands();
    getZoneById();
  }, [getIslands, getZoneById]);

  const updateZone = async (e) => {
    e.preventDefault();
    setLoad(true)
    try {
      const response = await axios.put(
        `${url}/zone/${id}`,
        {
          name: name,
          island: island_id,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setLoad(false)
      toast.success('Actualizado', {style: toastStyle});
      navigate("/listZone");
    } catch (error) {
      setLoad(false)
      toast.error(solveMessage(error), {style: toastStyle});
  };
  }
  
  return (
    <div className="container-sm pt-4 text-black ">
      <div className="mx-auto w-75">    
          <p className="small" style={{marginLeft: '105px'}}>
            <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
            <Link to='/listZone' className="mutec text-decoration-none ic">Zona  »  </Link>
            <span className="ic">Editar Zona</span>
          </p>
        
          <h5 className=" mb-3 floa-start ic" style={{ fontFamily: "Roboto", marginLeft: '105px'}}>
            Editar Zona
          </h5>
      
        <form className=" p-3 mx-auto w-75 shadow-lg bg-white smollText" onSubmit={updateZone}>
          <div className="mb-3 mt-3">
            <label for="name" className="form-label labeFormMargin">
              Nome{" "}
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
            </label>
            <input
              className="form-control form-control-sm"
              id="name"
              type="text"
              placeholder="Nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mb-3 mt-3">
            <label for="island_id" className="form-label labeFormMargin">
              Ilha{" "}
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
            </label>
            <select
              id="island_id"
              className="form-select form-select-sm"
              value={island_id}
              onChange={(e) => setIsland_id(e.target.value)}
            >
              <option value="" selected></option>
              {islands.map((island) => (
                <option value={island.id}>{island.name}</option>
              ))}
            </select>
          </div>

          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light"
              style={{ backgroundColor: "#122C48" }}
            >
              {load === true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>

            <Link
              to="/listZone"
              type="button"
              className="btn btn-sm px-3 float-end me-2"
              style={{ borderColor: "#122C48", color: "#122C48" }}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditZone;
