import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import List from "../List";
import { toast } from "react-toastify";
const ListCompany = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Estado para a página atual
  const [totalPages, setTotalPages] = useState(0); // Estado para o total de páginas
  const { user } = useAuth();
  const toastStyle = { fontSize: '13.3px' };
  const columnTitles = [
    { key: "name", value: "Nome" },
    { key: "nif", value: "NIF" },
    //{ key: "address", value: "Endereço" }, 
    { key: "email", value: "Email" },
   // { key: "telephone", value: "Telefone" },
  //  { key: "responsable", value: "Responsável" }, 
  ];

  // Função para buscar os dados da API antiga
const getOldApiCompanies = useCallback(async () => {
    const response = await axios.get(`${url}/company`, {
        headers: {
            Authorization: "Bearer " + user.access_token,
        },
    });

    const data = Array.isArray(response.data) ? response.data : [response.data];

    return data.map((v) => ({
        id: v.id,
        name: v.name,
        nif: v.nif,
        address: v.address ?? "-", // Substitui 'null' por '-'
        email: !v.email || v.email === "NULL" ? "-" : v.email, // Substitui 'null' ou 'NULL' por '-'
        telephone: !v.telephone || v.telephone === "NULL" ? "-" : v.telephone,
        responsable: v.responsable ? v.responsable.name : "-",
        created_at: v.created_at,
        source: v.isExternalCompany ? "new" : "old",
    }));
}, [url, user.access_token]);

// Função para buscar e definir os dados da API antiga
const getCompanies = useCallback(async () => {
    try {
        const oldApiCompanies = await getOldApiCompanies();

        // Ordenar as empresas por data de criação (created_at)
        oldApiCompanies.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        setCompanies(oldApiCompanies);
        setFiltered(oldApiCompanies);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
}, [getOldApiCompanies]);

useEffect(() => {
    getCompanies();
}, [getCompanies]);

  const filter = (e) => {
    const pattern = RegExp(e.target.value, "i");
    const results = companies.filter(
      (company) =>
        company.name.match(pattern) ||
        company.address.match(pattern) ||
        company.email.match(pattern) ||
        company.responsable.match(pattern)
    );
    setFiltered(results);
  };

  const deleteCompany = async (id) => {
    try {
      await axios.delete(`${url}/company/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      await getCompanies();
      toast.success("Eliminado com sucesso", { style: toastStyle });
    } catch (error) {
      console.log(error);
      toast.error("Erro ao Eliminar", { style: toastStyle });
    }
  };

  return (
    <List
      title="Empresa"
      columnTitles={columnTitles}
      items={filtered}
      filter={filter}
      originLink="/listCompany#"
      newLink="/addCompany"
      viewBaseLink="/viewCompany"
      editBaseLink="/editCompany"
      deleteItem={deleteCompany}
      loading={loading}
    />
  );
};

export default ListCompany;
