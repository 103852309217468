import React from "react";

export const Pagination = ({ itensPorPagina, itensTotais, originLink, paginate, paginaAtual }) => {
  const numeroDePaginas = [];

  for (
    let index = 1;
    index <= Math.ceil(itensTotais / itensPorPagina);
    index++
  ) {
    numeroDePaginas.push(index);
  }
   
  return (
    <nav className="d-flex justify-content-center">
  <ul className="pagination">
    {paginaAtual > 3 && (
      <>
        <li className="page-item">
          <a onClick={() => paginate(1)} href={`${originLink}`} className="page-link">
            1
          </a>
        </li>
        <li className="page-item">
          <span className="page-link">...</span>
        </li>
      </>
    )}

    {numeroDePaginas
      .slice(Math.max(0, paginaAtual - 3), paginaAtual + 2)
      .map(numero => (
        <li key={numero} className={`page-item ${numero === paginaAtual ? 'bg-blue-400' : ''}`}>
          <a onClick={() => paginate(numero)} href={`${originLink}`} className="page-link">
            {numero}
          </a>
        </li>
      ))}
    {paginaAtual < numeroDePaginas.length - 2 && (
      <>
        <li className="page-item">
          <span className="page-link">...</span>
        </li>
        <li className="page-item">
          <a onClick={() => paginate(numeroDePaginas.length)} href={`${originLink}`} className="page-link">
            {numeroDePaginas.length}
          </a>
        </li>
      </>
    )}
  </ul>
</nav>
  );
};