import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import Home from "./components/Home";
import NotFound from "./components/NotFound";

//ISLAND
import ListIsland from "./components/island/ListIsland";
import AddIsland from "./components/island/AddIsland";
import ViewIsland from "./components/island/ViewIsland";
import EditIsland from "./components/island/EditIsland";

//ZONE
import ListZone from "./components/zone/ListZone";
import AddZone from "./components/zone/addZone";
import ViewZone from "./components/zone/ViewZone";
import EditZone from "./components/zone/EditZone";

//ROAD
import ListRoad from "./components/road/ListRoad";
import AddRoad from "./components/road/AddRoad";
import ViewRoad from "./components/road/ViewRoad";
import EditRoad from "./components/road/EditRoad";
import Map from "./components/road/Map";

//USER
import AddUser from "./components/user/AddUser";
import ListUser from "./components/user/ListUser";
import EditUser from "./components/user/EditUser";
import ViewUser from "./components/user/ViewUser";

//SECTION
import AddSection from "./components/section/AddSection";
import ViewSection from "./components/section/ViewSection";
import EditSection from "./components/section/EditSection";
import ListSection from "./components/section/ListSection";

//CONTRCT
import ListContract from "./components/contract/ListContract";
import AddContract from "./components/contract/AddContract";
import AddRoadContract from "./components/road/AddRoadContract";
import ViewContract from "./components/contract/ViewContract";

//COMPANY
import ListCompany from "./components/company/ListCompany";
import AddCompany from "./components/company/AddCompany";
import ViewCompany from "./components/company/ViewCompany";
import EditCompany from "./components/company/EditCompany";

//RESPONSABLE
import ListResponsable from "./components/responsable/ListResponsable";
import ViewResonsable from "./components/responsable/ViewResponsable";
import EditResponsable from "./components/responsable/EditResponsable";
import AddResponsable from "./components/responsable/AddResponsable";

import Evaluation from "./components/evaluation/Evaluation";
import EvaluationMap from "./components/evaluation/EvaluationMap";
import ViewEvaluation from "./components/evaluation/ViewEvaluation";
import EditEvaluationMap from "./components/evaluation/EditEvaluationMap";
import ViewEvaluationCompany from "./components/evaluation/ViewEvaluationCompany";
import EvaluationCompany from "./components/evaluation/EvaluationCompany";

import AddImage from "./components/image/AddImage";
import EditImage from "./components/image/EditImage";

//LOTE
import ListLote from "./components/lote/ListLote";
import AddLote from "./components/lote/AddLote";
import EditLote from "./components/lote/EditLote";
import ViewLote from "./components/lote/ViewLote";

import AutoEdit from "./components/user/AutoEdit";
import AddPonderacao from "./components/typeService/AddPonderacao";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
        <Route path="/publicRoadMap" element={<Map />} />
          <Route
            path="/evaluationCompany/:sectionCod/:phase"
            element={<EvaluationCompany />}
          />
          <Route
            path="ViewEvaluationCompany/:cod/:id"
            element={<ViewEvaluationCompany />}
          />

          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<NotFound />} />

            {/* ISLAND */}
            <Route path="/listIsland" element={<ListIsland />} />
            <Route path="/addIsland" element={<AddIsland />} />
            <Route path="/viewIsland/:id" element={<ViewIsland />} />
            <Route path="/editIsland/:id" element={<EditIsland />} />

            {/* ZONE */}
            <Route path="/listZone" element={<ListZone />} />
            <Route path="/addZone" element={<AddZone />} />
            <Route path="/viewZone/:id" element={<ViewZone />} />
            <Route path="/editZone/:id" element={<EditZone />} />

            {/* ROAD */}
            <Route path="/listRoad" element={<ListRoad />} />
            <Route path="/addRoad" element={<AddRoad />} />
            <Route path="/viewRoad/:id" element={<ViewRoad />} />
            <Route
              path="/viewRoad/:id/:contractId/:contrctName"
              element={<ViewRoad />}
            />
            <Route path="/editRoad/:id" element={<EditRoad />} />
            <Route path="/roadMap" element={<Map />} />

            {/* User */}
            <Route path="/addUser" element={<AddUser />} />
            <Route path="/listUser" element={<ListUser />} />
            <Route path="/editUser/:idUser" element={<EditUser />} />
            <Route path="/viewUser/:idUser" element={<ViewUser />} />
            <Route path="/autoEdit" element={<AutoEdit />} />

            {/* CONTRACT */}
            <Route path="/listContract" element={<ListContract />} />
            <Route path="/addContract" element={<AddContract />} />
            <Route
              path="/addRoadContract/:contractId"
              element={<AddRoadContract />}
            />

            {/*<Route path="/editContract/:id" element={<EditContract />} /> */}
            <Route path="/viewContract/:id" element={<ViewContract />} />

            {/* SECTION */}
            <Route
              path="/addSection/:roadId/:contractId/:roadExt/:totalsectionExt"
              element={<AddSection />}
            />
            <Route path="/addPonderac/:id" element={<ViewSection />} />
            <Route path="/editSection/:id" element={<EditSection />} />
            <Route path="/listSection" element={<ListSection />} />

            {/*TYPESERVICE */}
            <Route
              path="/addPonderacao/:contrato/:estradaCod/:serviceName/:subService/:serviceId/:sectionId"
              element={<AddPonderacao />}
            />

            {/* COMPANY */}
            <Route path="/listCompany" element={<ListCompany />} />
            <Route path="/addCompany" element={<AddCompany />} />
            <Route path="/viewCompany/:id" element={<ViewCompany />} />
            <Route path="/editCompany/:id" element={<EditCompany />} />

            {/* RESPONSABLE */}
            <Route path="/listResponsable" element={<ListResponsable />} />
            <Route path="/viewResponsable/:id" element={<ViewResonsable />} />
            <Route path="/editResponsable/:id" element={<EditResponsable />} />
            <Route path="/addResponsable" element={<AddResponsable />} />

            {/* Evaluation */}
            <Route
              path="/evaluation/:sectionId/:initialDate/:finalDate"
              element={<Evaluation />}
            />
            <Route
              path="/evaluation/:sectionId/:initialDate/:finalDate/map"
              element={<EvaluationMap />}
            />
            <Route path="/viewEvaluation/:id" element={<ViewEvaluation />} />
            <Route
              path="/editEvaluation/:id/map"
              element={<EditEvaluationMap />}
            />

            <Route path="/addImage" element={<AddImage />} />
            <Route path="/editImage" element={<EditImage />} />

            {/* LOTE */}
            <Route path="/listLote" element={<ListLote />} />
            <Route path="/addLote" element={<AddLote />} />
            <Route path="/editLote/:id" element={<EditLote />} />
            <Route path="/viewLote/:id" element={<ViewLote />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
