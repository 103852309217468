import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { formatDateTime } from "../utils";
import List from "../List";
import axios from "axios";

const ListZone = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [zones, setZones] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const columnTitles = [
    { key: "name", value: "Nome" },
    { key: "island", value: "Ilha" },
    { key: "created_at", value: "Data Criação" },
  ];

  const getZones = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/zone`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      const data = response.data.map((v) => ({
        ...v,
        created_at: formatDateTime(v.created_at),
        island: v.island.name,
      }));
      setZones(data);
      setFiltered(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [url, user.access_token]);

  useEffect(() => {
    getZones();
  }, [getZones]);

  const filter = (e) => {
    const pattern = RegExp(e.target.value, "i");
    const results = zones.filter(
      (zone) => zone.name.match(pattern) || zone.island.match(pattern)
    );
    setFiltered(results);
  };

  const deleteZone = async (id) => {
    try {
      await axios.delete(`${url}/zone/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      await getZones();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <List
      title="Zona"
      columnTitles={columnTitles}
      items={filtered}
      filter={filter}
      originLink="/listZone#"
      newLink="/addZone"
      viewBaseLink="/viewZone"
      editBaseLink="/editZone"
      deleteItem={deleteZone}
      loading={loading}
    />
  );
};

export default ListZone;
