import { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import {toast } from 'react-toastify';
import { solveMessage } from "../utils";

const ViewSection = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [contractIsClosed, setContractIsClosed] = useState("");
  const [criterionsPvmv, setCriterionsPvmv] = useState([]);
  const [typeservices, setTypeServices] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [estradac, setEstradaCodigo] = useState("");
  const [contractId, setContractId] = useState("");
  const [contrato, setContrato] = useState("");
  const [roadExt, setRoadExt] = useState("");
  const [ponderac, setPonderac] = useState(0);
  const [section, setSection] = useState([]);
  const [usr, setUser] = useState("");
  const { id } = useParams();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const texSize = {fontSize: '15px'};
  const textColor = {color: '#6080A3', fontSize: '17px'};
  const tableHeadColor = {color: '#6080A3'}
  

  useEffect(() => {
    (async () => {
      if (section) {
        const id = section.userId;
        try {
          const res = await axios.get(`${url}/keycloak/users/${id}`, {
            headers: { authorization: `Bearer ${user.access_token}` },
          });
          setUser(res.data);
          
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [section, url, user.access_token]);

  const getEvaluationsSection = useCallback(async () => {
    try {
      const response = await axios.get(
        `${url}/evaluation/getEvaluation/${id}`,
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setEvaluations(response.data);
    } catch (error) {
      console.log(solveMessage(error));
    }
  }, [url, id, user.access_token, setEvaluations]);


  const getSectionById = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/section/byId/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setSection(response.data[0]);
      setTypeServices(response.data[0].typeservices);

      if (response.data[0]) {
        setEstradaCodigo(response.data[0].road.codigo);
        setRoadExt(response.data[0].road.extensao);
        setContrato(response.data[0].contract.name);
        setContractIsClosed(response.data[0].contract.isClosed);
        setContractId(response.data[0].contract.id);
        setCriterionsPvmv(response.data[0].typeservices[0].criterions);
      }
      
    } catch (error) {
      console.log(error);
      
    }
  }, [
    url,
    id,
    user.access_token,
    setSection,
    setTypeServices,
    setEstradaCodigo,
    setRoadExt,
    setContrato,
    setContractIsClosed,
    setContractId,
    setCriterionsPvmv,
  ]);

  useEffect(() => {
    getEvaluationsSection();
    getSectionById();
  }, [getEvaluationsSection, getSectionById]);

  if (section.tipoHobra === "Calcada") {
     delete criterionsPvmv[2];
  }

  const ponderacValue = async (e) => {
    const getPonderac = e.target.value;
    setPonderac(getPonderac);
  };

   // retorna ponderaçao de um tipo de serviço
  const getPonderacao = (sectionId, serviceId, criterionId) => {
    const result = evaluations.filter((value) => {
      return (
        value.section.id === sectionId &&
        value.typeservice.id === serviceId &&
        value.criterion.id === criterionId
      );
    });
     
    if (result.length > 0) {
      return result[0].ponderacao;
    } else {
      return "";
    }
  };

  const savePonder = async (e) => {
    e.preventDefault();
    alert('ok')
  }  

  const addPonderacao = async (sectionId, serviceId, criterionId) => {
    if (ponderac < 1) {
      toast.error("Reponha Novamente com Valor Superior a 0");
      setPonderac(0);
      return;
    }

    // pega valor de ponderaçao de um tipo de serviço
    const evaluatPonder = getPonderacao(sectionId, serviceId, criterionId);
    if (evaluatPonder) {
       
      const evaluation = evaluations.filter((value) => {
        return (
          value.section.id === sectionId &&
          value.typeservice.id === serviceId &&
          value.criterion.id === criterionId
        );
      });

      try {
        await axios.put(
          `${url}/evaluation/editPond/${evaluation[0].id}`,
          {
            ponderacao: ponderac,
          },
          {
            headers: {
              Authorization: "Bearer " + user.access_token,
            },
          }
        );

        setPonderac(0);
        toast.success('Actualizado');
        getEvaluationsSection();
      } catch (error) {
        console.log(error);
        
      }
      
    } else {
      try {
        await axios.post(
          `${url}/evaluation/addPonderac`,
          {
            section: sectionId,
            typeservice: serviceId,
            criterion: criterionId,
            ponderacao: ponderac,
          },
          {
            headers: {
              Authorization: "Bearer " + user.access_token,
            },
          }
        );
        setPonderac(0);
        toast.success('Adicionado com Sucesso');
        getEvaluationsSection();
      } catch (error) {
        console.log(solveMessage(error));
        
      }
    }
  };
 //console.log(criterionsPvmv);
  const color = contractIsClosed === true ? "#EF7800" : "";

  let pavimCriterions = [];

  criterionsPvmv.map((criter) =>
    pavimCriterions.push(
      
      <div className="card shadow-lg mb-2">
      <div key={criter.id} className="card-body">
          
      <div class="table-responsive">
        <table className="table table-sm table-bordered mx-auto w-75 table-sm bg-white smollText">
        <thead className="text-center">
          <tr>
            <th style={tableHeadColor}>Serviço</th>
            <td>{criter.typeservices[0].name}</td>
          </tr>
          <tr>
            <th style={tableHeadColor}>SubServiço</th>
            <td>{criter.name}</td>
          </tr>
          <tr>
          </tr>
        </thead>
      </table>
      </div>

      <div className="clearfix" style={{marginLeft: '134px'}}>
        {/* <button type="button" className="btn btn-sm float-start text-white sidItemL2 me-1"
          onClick={ () =>
            addPonderacao(section.id, criter.typeservices[0].id, criter.id)
           }
         >
              <span>Adicionar Ponderação</span>
              <i className='fas fa-plus-circle ms-2'></i>
        </button> */}
        <input
          type="number"
          className={ "form-control form-control-sm float-start"}
          onBlur={()=>addPonderacao(section.id, criter.typeservices[0].id, criter.id)}
          placeholder={getPonderacao(
            section.id, 
            criter.typeservices[0].id, 
            criter.id
          ) || "Adicionar Ponderação"
}
          min="1"
          onChange={ponderacValue}
          //disabled={section.isStarted === false ? false : true}
          style={{width: '134px'}}
        />
      </div>
      
      </div>
    </div>

    )
  );

  return (
    <div className="container-sm pt-4" >
        <p className="small" style={{marginLeft: ''}}>
        <Link to={`/addRoadContract/${contractId}`} className="mutec text-decoration-none ic">Contrato  »  </Link>
        <span className="ic">Tipos de Serviço</span>
        </p>
      <div className="bg-white shadow-lg mb-1 p-4">
        <div className="row">
           <div className="col-md-4">
              <span className="me-2 " style={textColor}>Contrato</span>
              <span className="" style={texSize}>{contrato}</span>
            </div>
            <div className="col-md-4">
              <span className="me-2" style={textColor}>Estrada</span>
              <span className="" style={texSize}>{estradac} / {roadExt}Km</span>
            </div>
           <div className="col-md-4"> 
             <span className="me-2" style={textColor}>Troço</span>
             <span className="" style={texSize}>{section.kmInicial} A {section.KmFinal}Km</span>
           </div>
        </div>
      </div>

        <div className="shadow-lg bg-white" 
          style={{paddingBottom: "35px", paddingLeft: "12px", paddingRight: "12px"}}
        >
            
            <div className="clearfix fw-lighter">
                <h5 className=" float-start ms-2 mt-4" style={{ fontFamily: "Roboto"}}>
                    Tipos de Serviço
                </h5>
                
            </div>

          <form  onSubmit={savePonder}>    
            {pavimCriterions} 

            {typeservices.length > 0 ? (
              typeservices.map((service) =>
                service.name === 'Pavimento da Via' ? (
                  ""
                ) : (
                   
          <div className="card shadow-lg mb-2">
          <div key={service.id} className="card-body">
              
          <div class="table-responsive">
            <table className="table table-sm table-bordered mx-auto w-75 bg-white smollText">
            <thead className="text-center">
              <tr>
                <th style={tableHeadColor}>Serviço</th>
                <td>{service.name}</td>
              </tr>
              <tr>
                <th style={tableHeadColor}>SubServiço</th>
                <td>{service.criterions[0].name !== 'Pavimento da Via' ? 'Nenhum' : service.criterions[0].name}</td>
              </tr>
              <tr>
              </tr>
            </thead>
          </table>
          </div>

          <div className="clearfix" style={{marginLeft: '134px'}}>
            {/* <button type="button" className="btn btn-sm float-start text-white sidItemL2 me-1"
              onClick={ () =>
                addPonderacao(
                  section.id,
                  service.id,
                  service.criterions[0].id
                )
              } 
            >
                  <span>Adicionar Ponderação</span>
                  <i className='fas fa-plus-circle ms-2'></i>
            </button> */}
            <input
              type="number"
              className={ "form-control form-control-sm float-start"}
              placeholder={getPonderacao(
                section.id,
                service.id,
                service.criterions[0].id
              )}
              min="1"
              onBlur={()=>addPonderacao(
                section.id,
                service.id,
                service.criterions[0].id
              )}
              onChange={ponderacValue}
              //disabled={section.isStarted === false ? false : true}
              style={{width: '125px'}}
            />
          </div>
          
          </div>
        </div>
                )
              )
            ) : (
              <tr>
                <td colSpan={4}>
                  <h5 className="text-center pt-5">Sem resultado!</h5>
                </td>
              </tr>
            )}
            <button
              className="btn btn-sm float-end text-light sidItemL2"
            >
              Salvar
            </button>
        </form>      
        </div>
    </div>
  );
};

export default ViewSection;
