import { useParams, Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useState, useEffect } from "react";
import axios from "axios";

const ViewResponsable = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [responsable, setResponsable] = useState([]);
  const { id } = useParams();
  const { user } = useAuth();
  const tableHeadColor = {color: '#6080A3'}

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${url}/responsable/${id}`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });

        setResponsable(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [url, id, user.access_token, setResponsable]);

  return (
    <div className="container-sm pt-4 text-black">

        <p className="small" style={{marginLeft: '139px'}}>
            <Link to='/listResponsable' className="mutec text-decoration-none ic">Responsavel  »  </Link>
            <span className="ic">Visualizar Responsavel</span>
        </p>  

        <h5 className=" mb-3 floa-start ic" style={{ fontFamily: "Roboto", marginLeft: '139px'}}>
          Visualização de Responsavel
        </h5>
     

        <table className="table table-sm table-bordered mx-auto w-75 shadow-lg bg-white smollText">
          <thead className="text-center">
            <tr>
              <th style={tableHeadColor}>Nome</th>
              <td>{responsable.name}</td>
            </tr>
            <tr style={{ backgroundColor: "#F3F3F3" }}>
              <th style={tableHeadColor}>Função</th>
              <td>{responsable.funct}</td>
            </tr>
            <tr>
              <th style={tableHeadColor}>Email</th>
              <td>{responsable.email}</td>
            </tr>
            <tr style={{ backgroundColor: "#F3F3F3" }}>
              <th style={tableHeadColor}>Telefone</th>
              <td>{responsable.telephone}</td>
            </tr>
            <tr>
              <th style={tableHeadColor}>Data Criação</th>
              <td>
                {!responsable.created_at
                  ? ""
                  : new Date(responsable.created_at).getDate() +
                    "/" +
                    (new Date(responsable.created_at).getMonth() + 1) +
                    "/" +
                    new Date(responsable.created_at).getFullYear() +
                    " " +
                    new Date(responsable.created_at).getHours() +
                    ":" +
                    new Date(responsable.created_at).getMinutes() +
                    ":" +
                    new Date(responsable.created_at).getSeconds()}
              </td>
            </tr>
          </thead>
        </table>
      
    </div>
  );
};

export default ViewResponsable;
