import { Link } from "react-router-dom";
import { useRoadForm } from "./useRoadForm";

export const RoadForm = ({ mapRoad }) => {
  const {
    loading,
    formData,
    loteOptions,
    zoneOptions,
    handleChange,
    handleSubmit,
    classificationOptions,
  } = useRoadForm();

  const labelStyle = {marginBottom: '-2px'}

  return (
    <>
      <div className="detail bg-white shadow border p-2">
      
        <form className=" ps-3 pe-3 mx-auto 
         w-75" onSubmit={handleSubmit}
         style={{fontSize: '14px'}}
         >
          <div className="row mb-2">
            <div className="col-md">
              <label htmlFor="code" className="form-label" style={labelStyle}>
                Codigo
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="code"
                name="code"
                type="text"
                value={formData.code}
                onChange={handleChange}
              />
            </div>

            <div className="col-md">
              <label htmlFor="name" className="form-label" style={labelStyle}>
                Nome
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm "
                id="name"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label htmlFor="classification" className="form-label" style={labelStyle}>
                Classificação
              </label>
              <select
                id="classification"
                name="classification"
                className="form-select form-select-sm"
                value={formData.classification}
                onChange={handleChange}
              >
                <option value=""></option>
                {classificationOptions.map((item) => (
                  <option value={item.key} key={item.key}>
                    em {item.value} classe
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md">
              <label htmlFor="extremes" className="form-label" style={labelStyle}>
                Pontos Extremos e Intermédios
              </label>
              <input
                className="form-control form-control-sm"
                id="extremes"
                name="extremes"
                type="text"
                value={formData.extremes}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label htmlFor="length" className="form-label" style={labelStyle}>
                Extensão (Km)
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="length"
                name="length"
                type="number"
                min={0}
                step={0.001}
                value={formData.length}
                onChange={handleChange}
              />
            </div>

            <div className="col-md">
              <label htmlFor="lote" className="form-label" style={labelStyle}>
                Lote
                <span className="text-danger" style={{ fontSize: "22px" }} >
                  *
                </span>
              </label>
              <select
                id="lote"
                name="lote"
                className="form-select form-select-sm"
                value={formData.lote}
                onChange={handleChange}
              >
                <option value=""></option>
                {loteOptions.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label htmlFor="zone" className="form-label" style={labelStyle}>
                Zona
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                className="form-select form-select-sm"
                id="zone"
                name="zone"
                value={formData.zone}
                onChange={handleChange}
              >
                <option value=""></option>
                {zoneOptions.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md">
              <label htmlFor="price" className="form-label" style={labelStyle}>
                Preço: $00
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="price"
                name="price"
                type="number"
                min={0}
                value={formData.price}
                onChange={handleChange}
              />
            </div>
          </div>
          {mapRoad && (
            <div>
              <input
                type="checkbox"
                id="connected"
                name="connected"
                onChange={handleChange}
                data-road={JSON.stringify(mapRoad)}
              />
              <label htmlFor="connected" style={labelStyle}>Conectar com: {mapRoad.code}</label>
            </div>
          )}

          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light sidItemL2"
              
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>

            <Link
              to="/listRoad"
              type="button"
              className="btn btn-sm px-3 float-end me-1 borderbp ic2"
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
      </>
  );
};
