import { Link, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import React, { useState } from "react";
import axios from "axios";
import {toast } from 'react-toastify';
import { solveMessage } from "../utils";

const AddPonderacao = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [ponderac, setPonderac] = useState(0);
  const { serviceName } = useParams();
  const { estradaCod } = useParams();
  const { subService } = useParams();
  const { serviceId } = useParams();
  const { sectionId } = useParams();
  const { contrato } = useParams();
  const { user } = useAuth();

  const savePonderac = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${url}/ponderacao`,
        {
          sectionId: sectionId,
          typeservice: serviceId,
          number: ponderac,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      toast.success('Adicionado com Sucesso', {style: {fontSize: '13.3px'}});
    } catch (error) {
      toast.error(solveMessage(error), {style: {fontSize: '13.3px'}});
    }
  };

  if (ponderac < 0) {
    setPonderac(0);
  }

  return (
    <div className="container-sm pt-4 text-black ">
      <h5 className="text-left mb-1 pt-4 ms-2" style={{ fontFamily: "Roboto" }}>
        Contrato {contrato} / Estrada {estradaCod} / Serviço {serviceName} /
        SubServiço {subService}
      </h5>
      <div className="detail shadow-lg ms-2 me-2">
        <form className=" p-3 mx-auto w-75" onSubmit={savePonderac}>
          <div className="mb-3 mt-3">
            <label for="name" className="form-label">
              Adicionar Valor de Ponderação
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
            </label>
            <input
              className="form-control"
              id="name"
              type="number"
              value={ponderac}
              onChange={(e) => setPonderac(e.target.value)}
            />
          </div>
          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light"
              style={{ backgroundColor: "#EF7800" }}
            >
              Salvar
            </button>
            <Link
              to="/listLote"
              type="button"
              className="btn btn-sm px-3 float-end me-2"
              style={{ borderColor: "#EF7800", color: "#EF7800" }}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPonderacao;
