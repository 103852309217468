import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "react-oidc-context";

const root = ReactDOM.createRoot(document.getElementById("root"));

const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig = {
  authority: process.env.REACT_APP_KEYCLOAK_AUTHORITY,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI,
  resource: process.env.REACT_APP_KEYCLOAK_RESOURCE,
  onSigninCallback,
  automaticSilentRenew: false,
  loadUserInfo: false,
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}
>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
