import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import {toast } from 'react-toastify';
import axios from "axios";
import { sectionsCount, solveMessage } from "../utils";

const BACKGROUND_STYLE = {
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  backgroundColor: "rgb(0,0,0, 0.7)",
  //sobre põe uma tela sobre outra
  zIndex: "1000",
  overflowY: "auto",
};

const MODAL_STYLE = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  padding: "20px",
  backgroundColor: "#fff",
  //borderRadius: '10px',
  color: "black",
  maxHeight: "420px",
  overflowX: "hiden",
  overflowY: "auto",
};

export default function EditSectionModal({
  isOpen,
  sectionIdClicked,
  editSectionClose,
}) {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { user } = useAuth();
  const [sectionR, setsectionR] = useState([]);
  const [contract_id, setContract_id] = useState();
  const [contract_name, setContract_name] = useState();
  const [kmInicial, setKmInicial] = useState();
  const [KmFinal, setKmFinal] = useState();
  const [tipo_hobra, setTipoHobra] = useState("");
  const [evaluationParameter, setEvaluationParameter] = useState("");
  const [road_id, setRoad_id] = useState();
  const [road_name, setRoad_name] = useState("");
  const [road_cod, setRoad_cod] = useState("");
  const [roadExt, setRoadExt] = useState();
  const [sectionExt, setSectionExt] = useState();
  const [load, setLoad] = useState(false);

  const getSectionRoadContract = useCallback(async () => {
    try {
      const response = await axios.get(
        `${url}/section/withRoad/${sectionIdClicked}`,
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setsectionR(response.data);
      setContract_id(response.data.contract.id)
      setContract_name(response.data.contract.name)
      setRoad_id(response.data.road.id)
      setRoad_cod(response.data.road.codigo)
      setRoad_name(response.data.road.name)
      setKmInicial(response.data.kmInicial)
      setKmFinal(response.data.KmFinal);
      setEvaluationParameter(response.data.evaluationParameter)
      setRoadExt(response.data.road.extensao)
      setTipoHobra(response.data.tipoHobra)
    } catch (error) {
      console.log(error);
    }
  });

  const getSectionsRoad = useCallback(async () => {
       try {
        if (road_id) {
          const response = await axios.get(
            `${url}/road/getRoad/${road_id}`,
            {
              headers: {
                Authorization: "Bearer " + user.access_token,
              },
            }
          );
          console.log(response.data);
          const countSectionExt = sectionsCount(response.data.sections, road_id);
          
        }
        
       } catch (error) {
        console.log(error);
       }
  })

  useEffect(() => {
    if (sectionR.length === 0) {
       getSectionRoadContract();
    }
     getSectionsRoad()
  }, [getSectionRoadContract, getSectionsRoad]);

  const updateSection = async (e) => {
    e.preventDefault();
    setLoad(true)
    try {
      await axios.put(
        `${url}/section/${sectionIdClicked}`,
        {
          kmInicial: kmInicial,
          KmFinal: KmFinal,
          tipoHobra: tipo_hobra,
          road: road_id,
          contract: contract_id,
          evaluationParameter: evaluationParameter,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setLoad(false)
      toast.success('Actualizado', {style: {fontSize: '13.3px'}})
    } catch (error) {
      setLoad(false)
      toast.error(solveMessage(error), {style: {fontSize: '13.3px'}})
    }
  };

  if (isOpen) {
    return (
      <div className="" style={BACKGROUND_STYLE}>
        <div className="smollText" id="modalContainer" style={MODAL_STYLE}>
          <h6 className="ms-1 " style={{ fontFamily: "Roboto" }}>
            Editar Troço
          </h6>
          <form onSubmit={updateSection}>
            <div className="row">
              <div className="col-md">
                <label for="road_id" className="form-label">
                  Codigo de Estrada
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <select id="road_id" className="form-select form-select-sm">
                  <option value={road_id}>{road_cod}</option>
                </select>
              </div>

              <div className="col-md">
                <label for="contract_id" className="form-label">
                  Contrato
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <select id="contract_id" className="form-select form-select-sm">
                  <option value={contract_id}>{contract_name}</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <label for="kmInicial" className="form-label">
                  Km Inicial
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-sm"
                  id="kmInicial"
                  type="number"
                  placeholder=""
                  value={kmInicial}
                  onChange={(e) => setKmInicial(e.target.value)}
                  //readonly="true"
                />
              </div>

              <div className="col-md">
                <label for="KmFinal" className="form-label">
                  Km Final
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-sm"
                  id="KmFinal"
                  type="number"
                  placeholder=""
                  value={KmFinal}
                  onChange={(e) => setKmFinal(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <label for="tipo_hobra" className="form-label">
                  Tipo de Pavimento
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <select
                  id="tipo_hobra"
                  className="form-select form-select-sm"
                  value={tipo_hobra}
                  onChange={(e) => setTipoHobra(e.target.value)}
                >
                  <option value=""></option>
                  <option value="Calcada">Calçada</option>
                  <option value="Betuminoso">Betuminoso</option>
                  <option value="Misto">Misto</option>
                </select>
              </div>
              <div className="col-md">
                <label for="KmFinal" className="form-label">
                  Parametro de Avaliação
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-sm"
                  id="KmFinal"
                  type="number"
                  placeholder=""
                  value={evaluationParameter}
                  onChange={(e) => setEvaluationParameter(e.target.value)}
                />
              </div>
            </div>

            <div className="clearfix mt-3">
              <button className="btn btn-sm float-end text-light sidItemL2">
                {load === true ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    "Salvar"
                  )}
              </button>

              <button
                preventScrollReset={false}
                type="button"
                className="btn btn-sm px-3 float-end me-2 borderbp ic2"
                onClick={/*editSectionClose*/ () => window.location.reload(false)}
              >
                Cancelar
              </button>

              <span className="float-start ms-5 text-muted">
                {sectionExt} / {roadExt}Km
              </span>
            </div>
          </form>
          
        </div>
      </div>
    );
  }

  return null;
}
