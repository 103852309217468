import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
const ListSection = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [listFeedMessage, setListFeedMessage] = useState("default");
  const [foundSections, setFoundSections] = useState([]);
  const [sections, setSections] = useState([]);
  const { user } = useAuth();
  const toastStyle = { fontSize: '13.3px' };
  const getSections = useCallback(async () => {
    setListFeedMessage("");
    try {
      const response = await axios.get(`${url}/section/${user.profile.sub}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setSections(response.data);
      setFoundSections(response.data);
      setListFeedMessage(response.statusText);
    } catch (error) {
      setListFeedMessage(error.response.statusText);
      console.log(error);
    }
  }, [
    url,
    user.profile.sub,
    user.access_token,
    setSections,
    setFoundSections,
    setListFeedMessage,
  ]);

  useEffect(() => {
    getSections();
  }, [getSections]);

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = sections.filter((section) => {
        return section.road.codigo
          .toLowerCase()
          .startsWith(keyword.toLowerCase());
      });
      setFoundSections(results);
    } else {
      setFoundSections(sections);
    }
  };

  const deleteSection = async (section_id) => {
    try {
      await axios.patch(
        `${url}/section/${section_id}/${user.profile.sub}`,
        { status: 0 },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      toast.success("Eliminado com sucesso", { style: toastStyle });
      await getSections();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao Eliminar", { style: toastStyle });
    }
  };

  return (
    <div className="container-sm pt-4 text-black">
      <h4 className="text-left mb-1 pt-4 ms-2" style={{ fontFamily: "Roboto" }}>
        Troços de Estrada
      </h4>
      <div className="detail shadow-lg ms-2 me-2 ps-3 pe-3 pt-2 pb-2">
        <div className="clearfix mb-2">
          <Link
            to={`/addSection/${0}`}
            className="btn btn-sm float-end text-light"
            style={{ backgroundColor: "#EF7800" }}
          >
            <i className="fas fa-plus pe-1"></i>Novo
          </Link>
          <input
            type="search"
            onChange={filter}
            className="form-control float-start w-25 fas fa-search"
            placeholder="&#xf002;"
            style={{ backgroundColor: "#F3F3F3" }}
          />
        </div>
        <table className=" table table-bordered  p-3">
          {listFeedMessage === "" ? (
            <caption className="spinner-border logt"></caption>
          ) : (
            ""
          )}
          <thead>
            <tr style={{ backgroundColor: "#F3F3F3" }}>
              <th>Nº</th>
              <th>Codigo Estrada</th>
              <th>Nome Estrada</th>
              <th>Lote</th>
              <th>Km Inicial</th>
              <th>Km Final</th>
              <th>Inspetor </th>
              <th className="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            {foundSections.length > 0 ? (
              foundSections.map((section, index) => (
                <tr key={section.id}>
                  <td>{index + 1}</td>
                  <td>{section.road.codigo}</td>
                  <td>{section.road.name}</td>
                  <td>{section.road.lote.name}</td>
                  <td>{section.kmInicial + " Km"}</td>
                  <td>{section.KmFinal + " Km"}</td>
                  <td>{section.user.name}</td>
                  <td className="text-center">
                    <Link
                      to={`/viewSection/${section.id}`}
                      className="btn p-2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Vizualizar"
                    >
                      {section.isConcluded === true ? (
                        <i
                          className="fas fa-eye text-success"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                          title="Avaliado"
                        ></i>
                      ) : (
                        <i
                          className="fas fa-eye"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                          title="Não Avaliado"
                        ></i>
                      )}
                    </Link>
                    <Link
                      to={`/editSection/${section.id}`}
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Actualizar"
                      className={
                        section.contract.isClosed === false
                          ? "btn fa fa-edit "
                          : "btn fa fa-edit disabled"
                      }
                    ></Link>
                    <button
                      onClick={() =>
                        window.confirm("Confirmar Deleção")
                          ? deleteSection(section.id)
                          : ""
                      }
                      style={{ color: "red" }}
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Deletar"
                      className={
                        section.contract.isClosed === false
                          ? "btn fas fa-trash-alt p-2 "
                          : "btn fas fa-trash-alt p-2 disabled"
                      }
                    ></button>
                  </td>
                </tr>
              ))
            ) : (
              <h4 className="text-center pt-5">Sem Resultado!</h4>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListSection;
