import { useParams, Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useState, useEffect } from "react";
import axios from "axios";

const ViewLote = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [island_name, setIsland_name] = useState("");
  const [lote, setLote] = useState([]);
  const { id } = useParams();
  const { user } = useAuth();
  const tableHeadColor = {color: '#6080A3'}

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${url}/lote/${id}`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });
        setLote(response.data[0]);
        setIsland_name(response.data[0].island.name);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [url, id, user.access_token, setLote, setIsland_name]);

  return (
    <div className="container-sm pt-4 text-black">
      
          <p className="small" style={{marginLeft: '139px'}}>
            <Link to='/listLote' className="mutec text-decoration-none ic">Lote  »  </Link>
            <span className="ic">Visualizar Lote</span>
          </p>
          <h5 className=" mb-3 ic" style={{ fontFamily: "Roboto", marginLeft: '139px'}}>
            Visualização de Lote
          </h5>
        
        <table className="table table-sm table-bordered table-sm mx-auto w-75 shadow-lg bg-white smollText">
          <thead className="text-center" >
            <tr>
              <th style={tableHeadColor}>Nome</th>
              <td >{lote.name}</td>
            </tr>
            <tr style={{ backgroundColor: "#F3F3F3" }}>
              <th style={tableHeadColor}>Ilha</th>
              <td>{island_name}</td>
            </tr>
            <tr>
              <th style={tableHeadColor}>Data Criação</th>
              <td>
                {!lote.created_at
                  ? ""
                  : new Date(lote.created_at).getDate() +
                    "/" +
                    (new Date(lote.created_at).getMonth() + 1) +
                    "/" +
                    new Date(lote.created_at).getFullYear() +
                    " " +
                    new Date(lote.created_at).getHours() +
                    ":" +
                    new Date(lote.created_at).getMinutes() +
                    ":" +
                    new Date(lote.created_at).getSeconds()}
              </td>
            </tr>
          </thead>
        </table>
      
    </div>
  );
};

export default ViewLote;
