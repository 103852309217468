export function dateFormat(data) {
  const date =
    new Date(data).getDate() +
    "/" +
    (new Date(data).getMonth() + 1) +
    "/" +
    new Date(data).getFullYear();

  return date;
}

export function dateTimeFormat(dat) {
  const year = new Date(dat).getFullYear();
  const month = String(new Date(dat).getMonth() + 1).padStart(2, "0");
  const day = String(new Date(dat).getDate()).padStart(2, "0");


  return `${year}-${month}-${day}`;
}

export function checkRodSection(roadsContract, sectionsContract) {
  var roadSectionsCount = 0;
  if (roadsContract.length > 0) {
    roadsContract.map((road, index) => {
      const sectionRoad = sectionsContract.filter(
        (section) => section.road.id === road.id
      ).length;
      if (sectionRoad === 0) {
        roadSectionsCount = roadSectionsCount + 1;
      }
    });
  }
  return roadSectionsCount;
}

export function checkSectionTypeServicesEvaluations(sectionsContract) {
  var validated = false;

  sectionsContract.map((sect, index) => {
    const typeServiceLength = sect.typeservices.length;
    const pavimentViaSubService = sect.typeservices[0].criterions.length - 2;
    const EvaluationsCount = sect.evaluations.length;
    if (typeServiceLength + pavimentViaSubService === EvaluationsCount) {
      validated = true;
    }
  });
  return validated;
}

export function getInspector(inspectors, userId) {
  const inspector = inspectors.filter((insp) => insp.id === userId);

  return `${inspector[0]?.firstName} ${inspector[0]?.lastName}`;
}

export function totalValue(roadsContract) {
  var totalv = 0;
  roadsContract.map((road) =>
    road.status === true ? (totalv = totalv + road.totalValue) : ""
  );
  return totalv;
}

export function differenceMonths(initialDate, finalDate) {
  var date1 = new Date(initialDate);
  var date2 = new Date(finalDate);
  var difference = Math.abs(date2 - date1);
  var days = difference / (1000 * 3600 * 24);
  var months = (days * 1) / 30;
  var monthsRound = Math.round(months);
  var monthsValidate = monthsRound < 1 ? 1 : monthsRound;
  return monthsValidate;
}

export function mensalValue(initialDate, finalDate, roadsContract) {
  var months = differenceMonths(initialDate, finalDate);
  var total = totalValue(roadsContract);
  var mensal = total / months;
  return Math.round(mensal);
}

export function acertoMes(initialDate, finalDate, roadsContract) {
  var months = differenceMonths(initialDate, finalDate);
  var mensal = mensalValue(initialDate, finalDate, roadsContract);
  var realTotalValue = totalValue(roadsContract);
  var roundedTotalValue = mensal * months;
  var lastMonthValue = 0;
  var difference = 0;
  if (roundedTotalValue < realTotalValue) {
    difference = realTotalValue - roundedTotalValue;
    lastMonthValue = mensal + difference;
  } else if (roundedTotalValue > realTotalValue) {
    difference = roundedTotalValue - realTotalValue;
    lastMonthValue = mensal - difference;
  } else if (roundedTotalValue === realTotalValue) {
    lastMonthValue = mensal;
  }
  return lastMonthValue;
}

export function inconformPerc(extencInconf, kmFinal, kmInicial) {
  var inconf = (extencInconf * 100) / (kmFinal - kmInicial);
  var inconfRound = Math.round(inconf);
  return inconfRound;
}

export function conformPerc(extencInconf, kmFinal, kmInicial) {
  var inconf = inconformPerc(extencInconf, kmFinal, kmInicial);
  var conf = 100 - inconf;
  var confRound = Math.round(conf);
  return confRound;
}

export function inconformCount(positions, evaluationParameter) {
  var count = 0;
  positions.map((position, index) => {
    if (position.isConform === false && position.status) {
      count = count + evaluationParameter;
    }
  });

  return count;
}

export function desconto(positionsEvaluation, evaluationParameter) {
  var desconto = 0;
  positionsEvaluation.map((evaluation) => {
    var ponderac =
      inconformCount(evaluation.positions, evaluationParameter) > 0
        ? evaluation.ponderacao
        : 0;
    var percConf = conformPerc(
      inconformCount(evaluation.positions, evaluationParameter),
      evaluation.section.KmFinal,
      evaluation.section.kmInicial
    );
    var mult = (percConf / 100) * ponderac;
    desconto = desconto + mult;
    return desconto;
  });
  var descontoRound = Math.round(desconto);
  return descontoRound;
}

export function recebimentoMensal(
  positionsEvaluation,
  evaluationParameter,
  initialDate,
  finalDate,
  roadsContract
) {
  var percDesconto = desconto(positionsEvaluation, evaluationParameter);
  var mensalVal = mensalValue(initialDate, finalDate, roadsContract);
  var valorPercentDesconto = (percDesconto / 100) * mensalVal;
  var recebMensal = mensalVal - valorPercentDesconto;
  var recebMensalRound = Math.round(recebMensal);
  return recebMensalRound;
}

export function sectionsCount(sectionsContract, roadId) {
  const sectionsRoad = sectionsContract.filter(
    (section) => section.road.id === roadId && section.status === true
  );
  var countSectionExt = 0;
  if (sectionsRoad.length > 0) {
    sectionsRoad.map((sect) => {
      var sectionExt = sect.KmFinal - sect.kmInicial;
      countSectionExt = countSectionExt + sectionExt;
    });
  }
  return countSectionExt;
}

export const formatDateTime = (s) =>
  Intl.DateTimeFormat("pt-PT", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
   
  }).format(new Date(s));

export const formatDate = (s) =>
  Intl.DateTimeFormat("pt-PT", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    timeZone: "Atlantic/Cape_Verde",
  }).format(new Date(s));


  export const solveMessage = (error) => {
    if (error && error.response && error.response.data) {
      const { message } = error.response.data;
      if (typeof message === 'string') {
        return message;
      } else if (Array.isArray(message)) {
        return message[0];
      }
    }
    return "ERROR";
  }