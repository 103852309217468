import { Link, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useState, useEffect } from "react";
import axios from "axios";
import { solveMessage } from "../utils";

const ViewZone = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [islandName, setIslandName] = useState("");
  const [zone, setZone] = useState([]);
  const { id } = useParams();
  const { user } = useAuth();
  const tableHeadColor = {color: '#6080A3'}
  const lisLine = { marginTop: "-1px" };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${url}/zone/${id}`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });
        setZone(response.data[0]);
        setIslandName(response.data[0].island.name);
      } catch (error) {
        console.log(solveMessage(error));
      }
    })();
  }, [url, id, user.access_token, setZone, setIslandName]);

  return (
    <div className="container-sm pt-4 text-black">
      <div className="mx-auto w-75">
          <p className="small" style={{marginLeft: '105px'}}>
            <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
            <Link to='/listZone' className="mutec text-decoration-none ic">Zona  »  </Link>
            <span className="ic">Visualizar Zona</span>
          </p>

          <h5 className=" mb-3 floa-start ic" style={{ fontFamily: "Roboto", marginLeft: '105px'}}>
            Visualizar de Zona
          </h5>

        <div className="bg-white shadow-lg mb-1 pt-3 pb-3 mx-auto w-75">
          <div className="mx-auto w-75">
          <ul className="list-group">
            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Nome</span>
              <span className="mutec" style={{marginRight: '60px'}}>{zone.name}</span>
            </li><hr style={lisLine}/>

            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Ilha</span>
              <span className="mutec" style={{marginRight: '60px'}}>{islandName}</span>
            </li><hr style={lisLine}/>

            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Data Criação</span>
              <span className="mutec" style={{marginRight: '60px'}}>
                {!zone.created_at
                  ? ""
                  : new Date(zone.created_at).getDate() +
                    "/" +
                    (new Date(zone.created_at).getMonth() + 1) +
                    "/" +
                    new Date(zone.created_at).getFullYear() +
                    " " +
                    new Date(zone.created_at).getHours() +
                    ":" +
                    new Date(zone.created_at).getMinutes() +
                    ":" +
                    new Date(zone.created_at).getSeconds()}
              </span>
            </li>
          </ul>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ViewZone;
