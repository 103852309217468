import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useState } from "react";
import axios from "axios";

const AddResponsable = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [feedMessage, setFeedMessage] = useState("default");
  const [telephone, setTelephone] = useState("");
  const [resicende, setResicende] = useState("");
  const [funct, setFunct] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const { user } = useAuth();

  const saveResponsable = async (e) => {
    e.preventDefault();
    setFeedMessage("");
    try {
      const response = await axios.post(
        `${url}/responsable`,
        {
          name: name,
          funct: funct,
          email: email,
          telephone: telephone,
          resicende: resicende,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setFeedMessage(response.statusText);
      navigate("/listResponsable");
    } catch (error) {
      setFeedMessage(error.response.statusText);
      console.log(error);
    }
  };

  return (
    <div className="container-sm pt-4 text-black">

        <p className="small" style={{marginLeft: '139px'}}>
            <Link to='/listResponsable' className="mutec text-decoration-none ic">Responsavel  »  </Link>
            <span className="ic">Criar Responsavel</span>
        </p> 
      <h5 className="mb-3 ic" style={{ fontFamily: "Roboto", marginLeft: '139px' }}>
        Criar Responsável
      </h5>
      
        <form className=" p-3 mx-auto w-75 shadow-lg bg-white smollText" onSubmit={saveResponsable}>
          <div className="row">
            <div className="col-md">
              <label for="name" className="form-label labeFormMargin">
                Nome
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="name"
                type="text"
                placeholder=""
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label for="funct" className="form-label labeFormMargin">
                Função
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="funct"
                type="text"
                placeholder=""
                value={funct}
                onChange={(e) => setFunct(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <label for="email" className="form-label labeFormMargin">
                Email
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="email"
                type="text"
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label for="telephone" className="form-label labeFormMargin">
                Telefone
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="telephone"
                type="text"
                placeholder=""
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <label for="resicende" className="form-label labeFormMargin">
                Residência
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="resicende"
                type="text"
                placeholder=""
                value={resicende}
                onChange={(e) => setResicende(e.target.value)}
              />
            </div>
            <div className="col-md"></div>
          </div>
          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light sidItemL2"
            >
              {feedMessage === "" ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>
            <Link
              to="/listResponsable"
              type="button"
              className="btn btn-sm px-3 float-end me-2 borderbp ic2"
            >
              Cancelar
            </Link>
          </div>
        </form>
      
    </div>
  );
};

export default AddResponsable;
