import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import React from 'react';
import { solveMessage } from "../utils";

const ViewRoad = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { id } = useParams();
  const { contractId } = useParams();
  const { contrctName } = useParams();
  const [road, setRoad] = useState([]);
  const [zone, setZone] = useState("");
  const [value, setValue] = useState("");
  const [islandName, setIslandName] = useState("");
  const [loteName, setLoteName] = useState("");
  const [contractClosed, setContractClosed] = useState("");
  const [sectsContract, setSectsContract] = useState([]);
  //const [lastSection, setLastSection] = useState(0);
  const [totalsectionExt, setTotalsectionExt] = useState();
  const lisLine = { marginTop: "-1px" };
  
  const { user } = useAuth();
  const [inspector, setInspector] = useState(null);
  const tableHeadColor = {color: '#6080A3'}

  const getRoadById = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/road/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setRoad(response.data[0]);
      setZone(response.data[0].zone.name);
      setValue(response.data[0].totalValue);
      setIslandName(response.data[0].lote.island.name);
      setLoteName(response.data[0].lote.name);
    } catch (error) {
      console.log(solveMessage(error));
    }
  }, [
    url,
    id,
    user.access_token,
    setRoad,
    setZone,
    setValue,
    setIslandName,
    setLoteName,
  ]);
  

  useEffect(() => {
    getRoadById();
    
  }, [getRoadById]);
   
 
  return (
    <div className="container-sm pt-4 text-black">
      <div className="mx-auto w-80">
        <p className="small" style={{marginLeft: '150px'}}>
            <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
            <Link to='/listRoad' className="mutec text-decoration-none ic">Estrada  »  </Link>
            <span className="ic">Visualizar Estrada</span>
        </p>  

      <h5 className="mb-3 ic" style={{ fontFamily: "Roboto", marginLeft: '150px'}}>
         Visualizar Estrada { contrctName === undefined ? "" : road.codigo}
        {contrctName === undefined ? "" : " / Contrato " + contrctName}
      </h5>

        <div className="bg-white shadow-lg mb-1 pt-3 pb-3 mx-auto w-75">
          <div className="mx-auto w-75">
            <div className="col-sm-12">
          <ul className="list-group">
            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Codigo</span>
              <span className="mutec" style={{marginRight: '60px'}}>{road.codigo}</span>
            </li><hr style={lisLine}/>
            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Nome</span>
              <span className="mutec" style={{marginRight: '60px'}}>{road.name}</span>
            </li><hr style={lisLine}/>

            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Classificação</span>
              <span className="mutec" style={{marginRight: '60px'}}>{road.classificcao}</span>
            </li><hr style={lisLine}/>

            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Extensão</span>
              <span className="mutec" style={{marginRight: '60px'}}>{!road.extensao ? "" : road.extensao + "Km"}</span>
            </li><hr style={lisLine}/>

            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Ilha</span>
              <span className="mutec" style={{marginRight: '60px'}}>{islandName}</span>
            </li><hr style={lisLine}/>

            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Lote</span>
              <span className="mutec" style={{marginRight: '60px'}}>{loteName}</span>
            </li><hr style={lisLine}/>

            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Zona</span>
              <span className="mutec" style={{marginRight: '60px'}}>{zone}</span>
            </li><hr style={lisLine}/>

            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Preço</span>
              <span className="mutec" style={{marginRight: '60px'}}>{value}$00</span>
            </li><hr style={lisLine}/>

            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Pontos Extremo e Intermedios</span>
              <span className="mutec" style={{marginRight: '60px'}}>
                {!road.pontoExtremoIntermedio
                  ? ""
                  : road.pontoExtremoIntermedio}
              </span>
            </li><hr style={lisLine}/>

            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Data Criação</span>
              <span className="mutec" style={{marginRight: '60px'}}>
                {!road.created_at
                  ? ""
                  : new Date(road.created_at).getDate() +
                    "/" +
                    (new Date(road.created_at).getMonth() + 1) +
                    "/" +
                    new Date(road.created_at).getFullYear() +
                    " " +
                    new Date(road.created_at).getHours() +
                    ":" +
                    new Date(road.created_at).getMinutes() +
                    ":" +
                    new Date(road.created_at).getSeconds()}
              </span>
            </li>
          </ul>
          </div>
          </div>
        </div>
      </div>  
    </div>
  );
};

export default ViewRoad;
