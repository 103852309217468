import { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import {
  inconformPerc,
  conformPerc,
  mensalValue,
  desconto,
  recebimentoMensal,
  inconformCount,
} from "../utils.js";

const EvaluationCompany = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [listFeedMessage, setListFeedMessage] = useState("default");
  const [positionsEvaluation, setPositionEvaluation] = useState([]);
  const [roadsContract, setRoadsContract] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [roadCodigo, setRoadCodigo] = useState("");
  const [inicialKm, setInicialKm] = useState("");
  const [contrato, setContrato] = useState([]);
  //const [userName, setUserName] = useState("");
  const [finalKm, setFinalKm] = useState("");
  const { sectionCod } = useParams();
  const [evaluationParameter, setEvaluationParameter] = useState();
  const { phase } = useParams();
  

  const getEvaluation = useCallback(async () => {
    setListFeedMessage("");
    try {
      const response = await axios.get(
        `${url}/evaluation/evaluatCod/${sectionCod}/${phase}`
        
      );
        console.log(response.data);
        setPositionEvaluation(response.data);
        setContrato(response.data[0].section.contract);
        setCompanyName(response.data[0].section.contract.company.name);
        setInicialKm(response.data[0].section.kmInicial);
        setFinalKm(response.data[0].section.KmFinal);
        setEvaluationParameter(response.data[0].section.evaluationParameter)
        setRoadsContract(response.data[0].section.contract.roads);
        setRoadCodigo(response.data[0].section.road.codigo);
      
      setListFeedMessage('default');
    } catch (error) {
      setListFeedMessage('default');
      console.log(error);
    }
  }, [
    url,
    phase,
    setPositionEvaluation,
    setContrato,
    setCompanyName,
    setInicialKm,
    setFinalKm,
    setRoadsContract,
    setRoadCodigo,
  ]);

  useEffect(() => {
    getEvaluation();
  }, [getEvaluation]);

  return (
    <div className="container-sm pt-4">
      <h5 className="mb-2 clearfix ic2" style={{ fontFamily: "Roboto"}}>
        <span className="float-start">Contrato
          <small className="text-muted ms-2">
            48:CVC:7-8-2023
          </small>
        </span>
        <span className="float-end ms-5 ic2">
            Troço
            <small className="text-muted ms-2 me-2">
              0 A 5Km
            </small>
        </span>
        <span className="float-end ic2">
            Estrada
            <small className="text-muted ms-2 me-2">
              EN-ST-01 / 5Km
            </small>
        </span>
    </h5> 
        <table className="table table-sm table-bordered shadow-lg p-3 bg-white text-center align-middle">
          {listFeedMessage === "" ? (
            <caption className="spinner-border logt"></caption>
          ) : (
            ""
          )}
          <thead className="mediunText">
            <tr>
              <th>Nº</th>
              <th>Serviço</th>
              <th>SubServiço</th>
              <th>Anomalias</th>
              <th>Km Nao Conforme </th>
              <th>% Nao Conforme </th>
              <th>% Conforme </th>
              <th>Coeficiente Ponderação </th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody className="smollText border border-top-0">
            {positionsEvaluation.length ? (
              positionsEvaluation.map((evaluation, index) => (
                <tr key={evaluation.id}>
                  <td>{index + 1}</td>
                  <td>{evaluation.typeservice.name}</td>
                  <td>
                    {evaluation.typeservice.name !== "Pavimento da Via"
                      ? ""
                      : evaluation.criterion.name}
                  </td>
                  <td className="text-center">
                    { inconformCount(evaluation.positions, evaluationParameter) > 0 ? (
                      <i className="fas fa-times text-danger"></i>
                    ) : (
                      <i className="fa fa-check text-success"></i>
                    ) }
                  </td>
                  <td>{ inconformCount(evaluation.positions, evaluationParameter) + "Km" }</td>
                  <td>
                    { inconformPerc(
                      inconformCount(evaluation.positions, evaluationParameter),
                      evaluation.section.KmFinal,
                      evaluation.section.kmInicial
                    ) + " %" }
                  </td>
                  <td>
                    { conformPerc(
                      inconformCount(evaluation.positions, evaluationParameter),
                      evaluation.section.KmFinal,
                      evaluation.section.kmInicial
                    ) + " %" }
                  </td>
                  <td>
                    { inconformCount(evaluation.positions, evaluationParameter) > 0
                      ? evaluation.ponderacao
                  : 0 }
                  </td>
                  <td className="text-center">
                    <Link
                      to={`/ViewEvaluationCompany/${evaluation.cod}/${evaluation.id}`}
                      className="btn fas fa-eye p-2 ic2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Vizualizar"
                    ></Link>
                  </td>
                </tr>
              ))
            ) : (
              ''
            )}
          </tbody>
        </table>

        {positionsEvaluation.length === 0 ? (
          ""
        ) : (

          <div className="bg-white" style={{marginTop: '-15px'}}>
          <ul className="list-group pt-3" >
                  <li className="list-group-item border-0" >
                    <span className="ic">Proposta Mensal</span><br/> 
                    <span className="mutec">
                    {mensalValue(
                    contrato.initialDate,
                    contrato.finalDate,
                    roadsContract
                  ) + "$00" }
                    </span>
                  </li>
                  <li className="list-group-item border-0">
                    <span className="ic">% Desconto de Valor Mensal de Manutenção</span><br/> 
                    <span className="mutec">
                       { desconto(positionsEvaluation, evaluationParameter) + "%" }
                    </span>
                  </li>
                  <li className="list-group-item border-0">
                    <span className="ic">Recebimento Mensal</span><br/> 
                    <span className="mutec">
                      { recebimentoMensal(
                      positionsEvaluation,
                      evaluationParameter,
                      contrato.initialDate,
                      contrato.finalDate,
                      roadsContract
                    ) + "$00" }
                    </span>
                  </li>
           </ul>
          </div> 
         
        )}
      
    </div>
  );
};

export default EvaluationCompany;
