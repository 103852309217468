import { useParams, Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useState, useEffect } from "react";
import axios from "axios";
import { solveMessage } from "../utils";

const ViewIsland = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [island, setIsland] = useState([]);
  const { id } = useParams();
  const { user } = useAuth();
  const tableHeadColor = {color: '#6080A3'}
  const lisLine = { marginTop: "-1px" };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${url}/island/byid/${id}`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });

        setIsland(response.data[0]);
      } catch (error) {
        console.log(solveMessage(error));
      }
    })();
  }, [url, id, user.access_token]);

  return (
    <div className="container-sm pt-4 text-black">
      <div className="mx-auto w-80">
        <p className="small" style={{marginLeft: '150px'}}>
        <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
        <Link to='/listIsland' className="mutec text-decoration-none ic">Ilha  »  </Link>
        <span className="ic">Visualizar Ilha</span>
        </p>

          <h5 className=" mb-3 ic" style={{ fontFamily: "Roboto", marginLeft: '150px'}}>
            Visualizar Ilha
          </h5>

        <div className="bg-white shadow-lg mb-1 pt-3 pb-3 mx-auto w-75">
          <ul className="list-group">
            <div className="mx-auto w-75">
            <li className="list-group-item d-flex justify-content-between align-items-center border-0">
              <span>Nome</span>
              <span className="mutec" style={{marginRight: '60px'}}> {island.name} </span>
            </li><hr style={lisLine}/>
              
            <li className="list-group-item d-flex justify-content-between align-items-center border-0" >
              <span>Data Criação</span>
              <span className="mutec" style={{marginRight: '60px'}}>
                {!island.created_at
                  ? ""
                  : new Date(island.created_at).getDate() +
                    "/" +
                    (new Date(island.created_at).getMonth() + 1) +
                    "/" +
                    new Date(island.created_at).getFullYear() +
                    " " +
                    new Date(island.created_at).getHours() +
                    ":" +
                    new Date(island.created_at).getMinutes() +
                    ":" +
                    new Date(island.created_at).getSeconds()}
              </span>
            </li>
            </div>
          </ul>
        </div>
      </div> 
    </div>
  );
};

export default ViewIsland;
