import { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import {toast } from 'react-toastify';
import { solveMessage } from "../utils";

const AddIsland = () => {
  const auth = useAuth();
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const token = auth.user.access_token;
  const [load, setLoad] = useState(false);
  const toastStyle = {fontSize: '13.3px'};

  
  const saveIsland = async (e) => {
    e.preventDefault();
    setLoad(true)

    try {
      const response = await axios.post(
        `${URL}/island`,
        {
          name: name,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setLoad(false)
      toast.success('Adicionado com Sucesso', {style: toastStyle});
      navigate("/listIsland");
      
    } catch (error) {
      setLoad(false)       
      toast.error(solveMessage(error), {style: toastStyle});
    }
  };

  return (
    <div className="container-sm pt-4 text-black">
      <div className="mx-auto w-75">
      <p className="small" style={{marginLeft: '105px'}}>
        <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
        <Link to='/listIsland' className="mutec text-decoration-none ic">Ilha  »  </Link>
        <span className="ic">Criar Ilha</span>
        </p>
      <h5 className="mb-1 ic title" style={{ fontFamily: "Roboto", marginLeft: '105px' }}>
        Criar Ilha{" "}
      </h5>
      
        <form className="p-3 mx-auto w-75 shadow bg-white smollText" onSubmit={saveIsland}>
          <div className="mb-3 mt-3">
            <label htmlFor="name" className="form-label labeFormMargin">
              Nome
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
            </label>
            <input
              className="form-control form-control-sm"
              id="name"
              type="text"
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light sidItemL2"
            >
              {load === true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>

            <Link
              to="/listIsland"
              type="button"
              className="btn btn-sm px-3 float-end me-2 borderbp ic2"
            >
              Cancelar
            </Link>
          </div>
        </form>
      
      </div> 
    </div>
    
  );
};

export default AddIsland;
