import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {toast } from 'react-toastify';
import { solveMessage } from "../utils";

const EditRoad = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [pontoExtremoIntermedio, setPontoExtremoIntermedio] = useState("");
  const [classificcao, setClassificcao] = useState("");
  const [extensao, setExtensao] = useState("");
  const [zone_id, setZone_id] = useState("");
  const [lot_id, setLot_id] = useState("");
  const [codigo, setCodigo] = useState("");
  const [value, setValue] = useState("");
  const [name, setName] = useState("");
  const { id } = useParams();
  const [zones, setZones] = useState([]);
  const [lotes, setLotes] = useState([]);
  const { user } = useAuth();
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();

  const getRoadById = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/road/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setName(response.data[0].name);
      setZone_id(response.data[0].zone.id);
      setLot_id(response.data[0].lote.id);
      setPontoExtremoIntermedio(response.data[0].pontoExtremoIntermedio);
      setCodigo(response.data[0].codigo);
      setExtensao(response.data[0].extensao);
      setValue(response.data[0].totalValue);
      setClassificcao(response.data[0].classificcao);
    } catch (error) {
      console.log(solveMessage(error));
    }
  }, [
    url,
    id,
    user.access_token,
    setName,
    setZone_id,
    setLot_id,
    setPontoExtremoIntermedio,
    setCodigo,
    setExtensao,
    setValue,
    setClassificcao,
  ]);

  const getZones = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/zone`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setZones(response.data);
    } catch (error) {
      console.log(solveMessage(error));
    }
  }, [url, user.access_token, setZones]);

  const getLotes = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/lote`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setLotes(response.data);
    } catch (error) {
      console.log(solveMessage(error));
    }
  }, [url, user.access_token, setLotes]);

  useEffect(() => {
    getRoadById();
    getLotes();
    getZones();
  }, [getRoadById, getLotes, getZones]);

  const updateRoad = async (e) => {
    e.preventDefault();
    setLoad(true)
    try {
      const response = await axios.put(
        `${url}/road/${id}`,
        {
          name: name,
          zone: zone_id,
          lote: lot_id,
          codigo: codigo,
          extensao: extensao,
          totalValue: value,
          classificcao: classificcao,
          pontoExtremoIntermedio: pontoExtremoIntermedio,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setLoad(false)
      toast.success('Actualizado', {style: {fontSize: '13.3px'}});
      navigate("/listRoad");
    } catch (error) {
      setLoad(false)
      toast.error(solveMessage(error), {style: {fontSize: '13.3px'}});
    }
  };
  return (
    <div className="container-sm pt-4 text-black ">
      <div className="mx-auto w-75">
          <p className="small" style={{marginLeft: '105px'}}>
            <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
            <Link to='/listRoad' className="mutec text-decoration-none ic">Estrada  »  </Link>
            <span className="ic">Editar Estrada</span>
         </p>
        
          <h5 className=" mb-1 floa-start ic" style={{ fontFamily: "Roboto", marginLeft: '105px'}}>
            Editar Estrada
          </h5>
     
      
        <form className=" smollText p-3 mx-auto w-75 shadow-lg bg-white" onSubmit={updateRoad}>
          <div className="row">
            <div className="col-md">
              <label for="codigo" className="form-label labeFormlStyle">
                Codigo
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="codigo"
                type="text"
                placeholder=""
                value={codigo}
                onChange={(e) => setCodigo(e.target.value)}
              />
            </div>

            <div className="col-md" style={{ paddingTop: "10px" }}>
              <label for="name" className="form-label labeFormlStyle">
                Nome
              </label>
              <input
                className="form-control form-control-sm"
                id="name"
                type="text"
                placeholder=""
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md mt-1">
              <label for="classificcao" className="form-label labeFormlStyle">
                Classificação
              </label>
              <select
                id="classificcao"
                className="form-select form-select-sm"
                value={classificcao}
                onChange={(e) => setClassificcao(e.target.value)}
              >
                <option value=""></option>
                <option value="EM 1ª classe">EM 1ª classe</option>
                <option value="EM 2ª classe">EM 2ª classe</option>
                <option value="EM 3ª classe">EM 3ª classe</option>
                <option value="EM 4ª classe">EM 4ª classe</option>
                <option value="EM 5ª classe">EM 5ª classe</option>
              </select>
            </div>

            <div className="col-md mt-1">
              <label for="pontoExtremo" className="form-label labeFormlStyle">
                Pontos Extremos e Intermédios
              </label>
              <input
                className="form-control form-control-sm"
                id="pontoExtremo"
                type="text"
                placeholder=""
                value={pontoExtremoIntermedio}
                onChange={(e) => setPontoExtremoIntermedio(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label for="extensao" className="form-label labeFormlStyle">
                Extensão (Km)
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="extensao"
                type="test"
                placeholder=""
                value={extensao}
                onChange={(e) => setExtensao(e.target.value)}
              />
            </div>

            <div className="col-md">
              <label for="lot_id" className="form-label labeFormlStyle">
                Lote
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                id="lot_id"
                className="form-select form-select-sm"
                value={lot_id}
                onChange={(e) => setLot_id(e.target.value)}
              >
                <option value=""></option>
                {lotes.map((lote, index) => (
                  <option value={lote.id}>{lote.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label for="zon_id" className="form-label labeFormlStyle">
                Zona
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                id="zon_id"
                className="form-select form-select-sm"
                value={zone_id}
                onChange={(e) => setZone_id(e.target.value)}
              >
                <option value=""></option>
                {zones.map((zone, index) => (
                  <option value={zone.id}>{zone.name}</option>
                ))}
              </select>
            </div>

            <div className="col-md">
              <label for="value" className="form-label labeFormlStyle">
                Preço: $00
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="value"
                type="number"
                placeholder=""
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          </div>

          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light sidItemL2"
            >
              {load === true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>

            <Link
              to="/listRoad"
              type="button"
              className="btn btn-sm px-3 float-end me-1 borderbp ic2"
            >
              Cancelar
            </Link>
          </div>
        </form>
    </div>
    </div>
  );
};

export default EditRoad;
