import { RoadForm } from "./RoadForm";
import { Map } from "../mapa/Mapa";
import { useState } from "react";
import { Link } from "react-router-dom";

const AddRoad = () => {
  const [mapRoad, setMapRoad] = useState(null);

  return (
    <div className="container-sm pt-4 text-black">

      <p className="small">
            <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
            <Link to='/listRoad' className="mutec text-decoration-none ic">Estrada  »  </Link>
            <span className="ic">Criar Estrada</span>
      </p>
      <h5 className="mb-3 ic" style={{ fontFamily: "Roboto"}}>
        Criar Estrada
      </h5>

       <Map 
          style={{ width: "100%", height: "400px" }}
          setMapRoad={setMapRoad}
        />
        
      <RoadForm mapRoad={mapRoad} />
    </div>
  );
};

export default AddRoad;
