import { SideBar } from "../components/sidebar/sidebar";
import { Navbar } from "../components/navbar/navbar";
import { Outlet } from "react-router-dom";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { useEffect } from "react";
import AwaitApproval from "./AwaitApproval";

const Layout = () => {
  const { 
    isAuthenticated, 
    activeNavigator, 
    isLoading, 
    signinRedirect, 
    user, 
    signinSilent, 
    events 
  } = useAuth();

  // Função para monitorar a expiração do token e renovar
  const checkTokenExpiration = () => {
    if (user && user.expires_at) {
      const expirationTime = user.expires_at * 1000; // user.expires_at é em segundos
      const currentTime = new Date().getTime();
      const timeLeft = expirationTime - currentTime;
      console.log("Time left:", timeLeft);


      // Se o token expirar em menos de 60 segundos, renová-lo
      if (timeLeft < 60000) {
        signinSilent()
          .then(() => {
            console.log('Token renovado com sucesso');
          })
          .catch((error) => {
            console.error('Erro ao renovar o token', error);
            signinRedirect(); // Se o token não puder ser renovado, redireciona para login
          });
      }
    }
  };

  // UseEffect para verificar a expiração do token e tentar renová-lo
  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Verifica a cada 1 minuto

    return () => clearInterval(interval); // Limpa o intervalo quando o componente desmontar
  }, [user, signinSilent, signinRedirect]);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !isAuthenticated &&
      !activeNavigator &&
      !isLoading
    ) {
      signinRedirect();
    }
  }, [isAuthenticated, activeNavigator, isLoading, signinRedirect]);

  if (isAuthenticated && !user.profile.roles) {
    return (
      <div className="position-relative">
        {<Navbar />}
        <main style={{ marginTop: "58px" }}>
          <AwaitApproval />
        </main>
      </div>
    );
  } else if (isAuthenticated) {
    return (
      <div className="position-relative">
        <SideBar />
        {<Navbar />}
        <main style={{ marginTop: "58px" }}>
          <Outlet />
        </main>
      </div>
    );
  } else {
    return <span>Loading...</span>;
  }
};

export default Layout;
