import { Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";

export const Navbar = () => {
  const { user, signoutRedirect } = useAuth();
  const logout_uri =
    process.env.REACT_APP_LOGOUT_REDIRECT_URI ?? "http://localhost:3000";
    
  return (
    <nav
      id="main-navbar"
      className="navbar navbar-expand-lg navbar-light fixed-top pb-3 pt-3 shadow bgNav "
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>

        <ul className="navbar-nav ms-auto d-flex flex-row ">
          {!user ? (
            ""
          ) : (
            <li className="nav-item dropdown ">
            <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#">
              <i className='fas fa-user-circle me-2 ic'></i>
              <span className="ic">{user.profile.name}</span>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
            {
              !user.access_token ? 
              (
                <li><Link to="/login" className="dropdown-item" >Entrar</Link></li>
              ) : 
              (
                <li>
                <button 
                type="button"
                 className="dropdown-item" 
                 onClick={() =>
                  signoutRedirect({
                    post_logout_redirect_uri: logout_uri,
                  })
                 }
                 >
                  Sair
                </button>
               </li>
              )
            }
            </ul>
          </li>
          )}
        </ul>
      </div>
    </nav>
  );
};
